import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { parse } from 'utils/api'
import { getUniqueFieldValues } from 'utils/mapperHelper'
import { safeFetchJson, parsedResultOnSucessOrEmtpy } from 'utils/safeFetch'

import { TemplateSupplier, fetchTemplateSuppliers } from 'reducers/template-suppliers/templateSuppliersSlice'

const dataSetName = 'templateSupplierPrice'

export type TemplateSupplierPriceApi = Modify<{
  discount: number
  list_price: number
  minimum_order_qty: number
  unit_cost: number
  template_supplier_id: string
}, BaseEntityApi>

type Exceptions = {
  template_supplier_id: 'itemVendorId'
}

export type TemplateSupplierPrice = ApiToSlice<TemplateSupplierPriceApi, Exceptions>

export function getFields(): GetFields<TemplateSupplierPriceApi, TemplateSupplierPrice> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'discount': { dataSetName, dbField: 'discount', type: 'float' },
    'listPrice': { dataSetName, dbField: 'list_price', type: 'float' },
    'minimumOrderQty': { dataSetName, dbField: 'minimum_order_qty', type: 'float' },
    'unitCost': { dataSetName, dbField: 'unit_cost', type: 'float' },

    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'itemVendorId': { dataSetName, dbField: 'template_supplier_id', type: 'id' },

    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  }
}

const initialState = {
  fields: getFields(),
  data: [] as TemplateSupplierPrice[],
  count: 0,
}

export function parseTemplateSupplierPrice(templateSupplierPrice: TemplateSupplierPriceApi) {
  const fields = initialState.fields

  const options = {
    fields: fields,
    dataSetName,
  }
  return parse(templateSupplierPrice, options)
}

export async function fetchPricesByTemplateSupplierId(id: string): Promise<TemplateSupplierPrice[]> {
  const response = await safeFetchJson(`/new_api/inventories/templates/suppliers/${id}/prices`)

  return parsedResultOnSucessOrEmtpy(response, parseTemplateSupplierPrice)
}

export type fetchTemplateSupplierPricesParam = {
  supplierId: TemplateSupplier['supplierId'],
  templateId: TemplateSupplier['templateId'][] | TemplateSupplier['templateId'],
}

export type SimpleTemplateSupplierPrice = Pick<
    TemplateSupplierPrice,
    'minimumOrderQty' | 'listPrice' | 'unitCost' | 'discount'
  >

export type TemplateSupplierPricesData = {
  templateId: TemplateSupplier['templateId']
  supplierTemplate: TemplateSupplier
  supplierTemplatePrices: TemplateSupplierPrice[]
  prices: SimpleTemplateSupplierPrice[]
}

export const fetchTemplateSupplierPrices = async(
  data: fetchTemplateSupplierPricesParam,
): Promise<TemplateSupplierPricesData[]> => {
  const templateSuppliers = await fetchTemplateSuppliers(data)

  if (!templateSuppliers.length) return []

  const templateSupplier = templateSuppliers[0]
  const templateSupplierPrices = await fetchPricesByTemplateSupplierId(templateSupplier.id)

  const templateIds = getUniqueFieldValues(templateSuppliers, 'templateId')

  return templateIds.map((templateId: string) => {
    const supplierTemplate = templateSuppliers.find((templateSupplier) => templateSupplier.templateId === templateId)
    const supplierTemplatePrices = templateSupplierPrices
      .filter((price) => price.itemVendorId === supplierTemplate.id)

    const basePrice: SimpleTemplateSupplierPrice = {
      minimumOrderQty: 0,
      listPrice: supplierTemplate.listPrice,
      unitCost: supplierTemplate.unitCost,
      discount: supplierTemplate.discount,
    }

    const prices = (supplierTemplatePrices as SimpleTemplateSupplierPrice[]).concat(basePrice)
    prices.sort((a, b) => b.minimumOrderQty - a.minimumOrderQty)

    return {
      templateId,
      supplierTemplate,
      supplierTemplatePrices,
      prices: prices.map((price) => ({
        minimumOrderQty: price.minimumOrderQty,
        unitCost: price.unitCost,
        listPrice: price.listPrice,
        discount: price.discount,
      })),
    }
  })
}

export const getCorrectListPrice = (measure: number, prices: SimpleTemplateSupplierPrice[]) => {
  if (!prices?.length) return null

  return prices
    .sort((a, b) => b.minimumOrderQty - a.minimumOrderQty)
    .find((price) => price.minimumOrderQty <= measure)
}
