import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import { parseState, State, StateApi } from 'reducers/states/statesSlice'
import { parseSynonym, Synonym, SynonymApi } from 'reducers/synonyms/synonymsSlice'

const dataSetName = 'countryView'

const initialState = {
  dataSetName,
  fields: getFields(),
  countriesCount: 0,
  countries: [],
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload.data
    },
    setCountriesCount: (state, action) => {
      state.countriesCount = action.payload.count
    },
  },
})

export const { setCountries, setCountriesCount } = countriesSlice.actions

export type MapData = {
  primaryLanguage?: string,
  secondaryLanguage?: string,
}

export type CountryApi = Modify<{
  name: string,
  secondary_name: string,
  code: string,
  possible_names: string[],
  synonyms: SynonymApi[],
  states: StateApi[],
}, BaseEntityApi>

type Exceptions = {
  synonyms: 'lineItems'
}

export type Country = ApiToSlice<Modify<CountryApi, {
  synonyms: Synonym[],
  states: State[],
}>, Exceptions>

type CountryGetFields = GetFields<CountryApi, Country, MapData>

export function getFields(editOnly = false): CountryGetFields {
  const editFields: CountryGetFields = {
    id: { dataSetName, dbField: 'id', type: 'string' },
    // ! TODO (bzoretic): rework pour que ce soit synonyms et non lineItems (#DEV-I2282)
    lineItems: {
      dataSetName,
      dbField: 'synonyms',
      type: 'array',
      isEdit: true,
      parse: (countryApi) => countryApi.synonyms?.map((synonym) => parseSynonym(synonym)) || [],
    },
  }

  if (editOnly) return editFields

  const fields: CountryGetFields = {
    exist: { dataSetName, dbField: 'exist', type: 'boolean' },
    createdDate: { dataSetName, dbField: 'created_date', type: 'timestamp' },
    createdBy: { dataSetName, dbField: 'created_by', type: 'string' },
    createdById: { dataSetName, dbField: 'created_by_id', type: 'string' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    modifiedBy: { dataSetName, dbField: 'modified_by', type: 'string' },
    modifiedById: { dataSetName, dbField: 'modified_by_id', type: 'string' },
    name: {
      dataSetName,
      dbField: 'name',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'countries:country.fields.name.label',
        { language: options?.primaryLanguage },
      ),
    },
    secondaryName: {
      dataSetName,
      dbField: 'secondary_name',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'countries:country.fields.name.label',
        { language: options?.secondaryLanguage },
      ),
    },
    code: { dataSetName, dbField: 'code', type: 'string' },
    possibleNames: { dataSetName, dbField: 'possible_names', type: 'array' },
    states: {
      dataSetName,
      dbField: 'states',
      type: 'array',
      parse: (countryApi, mapData) => countryApi.states?.map((state) => parseState(state, mapData)) || [],
    },
  }

  return { ...fields, ...editFields }
}

export function getCountryTitle(country: Country): string {
  return country.name
}

export async function fetchCountriesByIds(ids: string[], mapData?: MapData, data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<CountryApi>(
    buildGetUrl(`/new_api/countries/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((country) => parseCountry(country, mapData)) :
    []
}

export function fetchCountries(mapData: MapData, fetchData?: Record<string, any>) {
  return async function fetchCountriesThunk(dispatch: AppDispatch) {
    const data = await _fetchCountries(fetchData, mapData)
    dispatch(setCountries({ data }))
    return data
  }
}

export async function _fetchCountries(fetchData: Record<string, any>, mapData: MapData) {
  let countries = []

  try {
    const { isSuccess, result } = await safeFetchJson<CountryApi>(
      buildGetUrl('/new_api/countries', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      countries = result.map((country) => parseCountry(country, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return countries
}

export function fetchCountriesCount(fetchData?: Record<string, any>) {
  return async function fetchCountriesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchCountriesCount(fetchData)
    dispatch(setCountriesCount({ count }))
    return count
  }
}

export async function _fetchCountriesCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/countries/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _updateCountry(id: string, updateData: Partial<CountryApi>, mapData: MapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ country: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<CountryApi, false>(
      `/new_api/countries/${id}`, requestOptions,
    )
    const country = isSuccess && !isJob<CountryApi, false>(result) ?
      parseCountry(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, country, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseCountry(country: CountryApi, mapData: MapData): Country {
  const options = {
    defaultData: getDefaultCountry(),
    fields: initialState.fields,
    dataSetName: dataSetName,
    ...mapData,
  }

  return parse(country, options)
}

function getDefaultCountry(): Country {
  return parse({}, { fields: initialState.fields })
}

export default countriesSlice.reducer
