import moment from 'moment-timezone'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { buildDictionary } from 'utils/mapperHelper'
import { safeFetch, safeFetchJson, isJob } from 'utils/safeFetch'

import { GET_RESOURCES, SET_RESOURCES, SET_PUNCH_DATA, UPDATE_WEEK_INFO_DATA } from './types'

const dataSetName = 'resource'
const shiftDetailDataSetName = 'shiftDetail'
const fields = getFields()

const initialState = {
  dataSetName,
  fields,
  clockedIn: [],
  clockedOut: [],
  punchData: { resource: null, onPinSuccess: getDefaultPinSuccess() } as PunchDataType,
  weekInfoData: {},
}

type PunchDataType = {
  resource: Resource
  onPinSuccess: (resource: Resource) => void
}

export function getDefaultPinSuccess() {
  return (resource: Resource) => {}
}

export default function resourcesReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case SET_PUNCH_DATA: {
    return { ...state, punchData: { ...state.punchData, ...payload } as PunchDataType }
  }
  case UPDATE_WEEK_INFO_DATA: {
    return { ...state, weekInfoData: { ...state.weekInfoData, ...payload } }
  }
  case GET_RESOURCES: {
    if (payload.isClockedIn) {
      return {
        ...state,
        clockedIn: payload.resources,
      }
    }
    return {
      ...state,
      clockedOut: payload.resources,
    }
  }
  case SET_RESOURCES: {
    const clockedIn = state.clockedIn.filter((resource) => resource.id !== payload.resource.id)
    const clockedOut = state.clockedOut.filter((resource) => resource.id !== payload.resource.id)
    if (payload.resource.currentClockId) {
      return {
        ...state,
        clockedIn: [...clockedIn, payload.resource],
        clockedOut: clockedOut,
      }
    }
    return {
      ...state,
      clockedIn: clockedIn,
      clockedOut: [...clockedOut, payload.resource],
    }
  }
  default: {
    return state
  }
  }
}

// resources exports
export type ResourceApi = Modify<{
  firstname: string
  lastname: string
  email: string
  plant_id: string
  fullname: string
  is_shift_selector: boolean
  shift_id: string
  shiftdetail_id: string
  shiftdetail_starthour: string
  shiftdetail_endhour: string
  shiftdetail_lunch: string
  shiftdetail_break1: string
  current_clock_timezone: string
  current_break_id: string
  current_break_break_type: string
  current_break_clockin: string
  current_break_break_time_minutes: string
  current_clock_clockin: string
  current_clock_clockout: string
  current_clock_id: string
  shift_code: string
  user_view: boolean
  user_view_editable: boolean
  department_id: string
  position_title: string
  is_salesperson: boolean
  is_buyer: boolean
}, BaseEntityApi>

type Exceptions = {
  firstname: 'firstName'
  lastname: 'lastName'
  fullname: 'fullName'
  shiftdetail_id: 'shiftDetailId'
  shiftdetail_starthour: 'shiftDetailStartHour'
  shiftdetail_endhour: 'shiftDetailEndHour'
  shiftdetail_lunch: 'shiftDetailLunchInMinutes'
  shiftdetail_break1: 'shiftDetailBreak1InMinutes'
  current_break_break_type: 'currentBreakType'
  current_break_break_time_minutes: 'currentBreakTimeMinutes'
}

export type Resource = ApiToSlice<
  Modify<ResourceApi, {
    shiftdetail_lunch: number
    shiftdetail_break1: number
    shift_start: moment.Moment
    shift_end: moment.Moment
    shift_length: number
    is_outside_shift_hours: boolean
    clock_in_date: string
    break_remaining_time: number
    is_missing_attendance: boolean
    id: number
  }>,
  Exceptions
>

export function getFields(): GetFields<ResourceApi, Resource, null, 'shiftdetail' | 'position'> {
  return {
    id: { dataSetName, dbField: 'id', type: 'id' },
    firstName: { dataSetName, dbField: 'firstname' },
    lastName: { dataSetName, dbField: 'lastname' },
    email: { dataSetName, dbField: 'email' },
    plantId: { dataSetName, dbField: 'plant_id' },
    fullName: { parse: (resource) => resource.fullname },
    isShiftSelector: { dataSetName, dbField: 'is_shift_selector' },
    shiftId: { dataSetName, dbField: 'shift_id' },
    shiftDetailStartHour: { dataSetName: shiftDetailDataSetName, dbField: 'starthour' },
    shiftDetailEndHour: { dataSetName: shiftDetailDataSetName, dbField: 'endhour' },
    shiftDetailLunchInMinutes: {
      dataSetName: shiftDetailDataSetName,
      dbField: 'lunch',
      parse: (resource) => timeToMinutes(resource, 'shiftdetail_lunch'),
    },
    shiftDetailBreak1InMinutes: {
      dataSetName: shiftDetailDataSetName,
      dbField: 'break1',
      parse: (resource) => timeToMinutes(resource, 'shiftdetail_break1'),
    },
    shiftDetailId: { dataSetName: shiftDetailDataSetName, dbField: 'id', type: 'id' },
    currentClockTimezone: { dataSetName, dbField: 'current_clock_timezone' },
    currentBreakId: { dataSetName, dbField: 'current_break_id' },
    currentBreakType: { dataSetName, dbField: 'current_break_break_type' },
    currentBreakClockin: { dataSetName, dbField: 'current_break_clockin' },
    currentBreakTimeMinutes: { dataSetName, dbField: 'current_break_break_time_minutes' },
    currentClockClockin: { dataSetName, dbField: 'current_clock_clockin' },
    currentClockClockout: { dataSetName, dbField: 'current_clock_clockout' },
    currentClockId: { dataSetName, dbField: 'current_clock_id' },
    shiftCode: { dataSetName, dbField: 'shift_code' },
    userView: { dataSetName, dbField: 'user_view', type: 'boolean' },
    userViewEditable: { dataSetName, dbField: 'user_view_editable', type: 'boolean' },
    departmentId: { parse: (resource) => resource.department_id || 'other' },
    positionTitle: { dataSetName: 'position', dbField: 'title' },
    isSalesperson: { dataSetName, dbField: 'is_salesperson', type: 'boolean' },
    isBuyer: { dataSetName, dbField: 'is_buyer', type: 'boolean' },
    shiftStart: { parse: _parseShiftStart },
    shiftEnd: { parse: _parseShiftEnd },
    shiftLength: { parse: _parseShiftLength },
    isOutsideShiftHours: { parse: _parseIsOutsideShiftHours },
    clockInDate: { parse: _parseClockInDate },
    breakRemainingTime: { parse: _parseBreakRemainingTime },
    isMissingAttendance: { parse: _parseIsMissingAttendance },
  }
}

export function fetchResources(data: any) {
  return async function fetchResourcesThunk(dispatch: AppDispatch) {
    const resources = (await _fetchResources(data)).filter(
      (obj) => !(obj.firstName?.toUpperCase() === 'ALIX' && obj.lastName?.toUpperCase() === 'AGILEAN'),
    )

    dispatch({ type: GET_RESOURCES, payload: { resources, isClockedIn: data.isClockedIn } })
  }
}

export async function _fetchResources(data: any = {}) {
  let resources = []

  try {
    const { isSuccess, result } = await safeFetchJson<ResourceApi>(buildGetUrl('/new_api/resources', data))
    if (isSuccess && !isJob(result)) {
      const mapData = { isClockedIn: !!data?.isClockedIn }
      resources = result.map((resource) => parseResource(resource, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return resources
}

export async function fetchResourceByIds(ids: string[], data?: any) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ResourceApi>(buildGetUrl(`/new_api/resources/${ids}`, data))
  return isSuccess && !isJob(result) ? result.map((resource) => parseResource(resource)) : []
}

export async function fetchShiftByShiftId(shiftId: string | number) {
  if (!shiftId) return null

  const date = new Date()
  const currentDayOfWeek = date.getDay() + 1
  let shift = null
  try {
    const details = await (await safeFetch(buildGetUrl(`/api/resources/details/${shiftId}`))).json()
    shift = details.find((shift: any) => shift.dayofweek === currentDayOfWeek)
  } catch (err) {
    console.error(err)
  }
  return shift
}

export async function updateResources(ids: (string | number)[], data: any) {
  let resources = []
  try {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: data }),
    }

    const { isSuccess, result } = await safeFetchJson<ResourceApi>(`/new_api/resources/${ids}`, requestOptions)

    if (isSuccess && !isJob(result)) {
      resources = result.map((resource) => parseResource(resource))
    }
  } catch (err) {
    console.error(err)
  }

  return resources
}

export function setResource(resource: Resource, isClockedIn = false) {
  return async function setResourceThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_RESOURCES, payload: { resource, isClockedIn } })
  }
}

export async function fetchCurrentTimeEntry(resourceId: string | number) {
  if (!resourceId) return null

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ date: new Date() }),
  }

  let result = null
  try {
    const response = await (await safeFetch(`/api/resources/shift-of/${resourceId}`, requestOptions)).json()
    const timezone = response.timezone || 'Canada/Eastern'
    result = {
      endDate: moment(response.shiftend).tz(timezone).format('YYYY-MM-DD HH:mm'),
      timeEntryId: response.time_entry_id,
    }
  } catch (err) {
    console.error(err)
  }

  return result
}

export async function saveClock(body: any, type: 'in' | 'out') {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }
    return await (await safeFetch(`/new_api/resources/clock/${type}`, requestOptions)).json()
  } catch (err) {
    console.error(err)
  }
}

export async function saveComment(body: any) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    }

    return await (await safeFetch('/new_api/comments', requestOptions)).json()
  } catch (err) {
    console.error(err)
  }
}

export async function fetchDepartmentInfo(resourceId: string | number) {
  let departments = []
  try {
    const response = await (await safeFetch(buildGetUrl(`/new_api/resources/${resourceId}/department`))).json()
    if (response.isSuccess) {
      departments = response.result
    }
  } catch (err) {
    console.error(err)
  }
  return departments
}

export function getFetchWeekInfoBody(resource: Resource) {
  const timezone = resource?.currentClockTimezone || moment.tz.guess()
  const today = moment().tz(timezone.toString())
  const body = {
    startDate: moment.utc(moment(today).startOf('week')).format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment.utc(moment(today).startOf('week').add(7, 'days')).format('YYYY-MM-DD HH:mm:ss'),
  } as Record<string, any>
  if (!!resource?.id) body.resourceId = resource.id
  return body
}

export function fetchWeekInfoData(body: any) {
  return async function fetchWeekInfoDataThunk(dispatch: AppDispatch) {
    let weekInfos = []
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/resources/time-entries/kpi', body))).json()
      if (result.isSuccess) {
        weekInfos = result.result
        dispatch({ type: UPDATE_WEEK_INFO_DATA, payload: buildDictionary(weekInfos, 'id') })
      }
    } catch (err) {
      console.error(err)
    }
    return weekInfos
  }
}

export function setPunchData(payload: Partial<PunchDataType>) {
  return function setPunchDataThunk(dispatch: AppDispatch) {
    dispatch({ type: SET_PUNCH_DATA, payload })
  }
}

export async function fetchLiabilitiesById(resourceId: string | number) {
  let liabilities = []
  try {
    liabilities = await (await safeFetch(buildGetUrl(`/api/resources/liabilities/${resourceId}`))).json()
  } catch (err) {
    console.error(err)
  }
  return liabilities
}

// parsing functions
export function parseResource(resource: ResourceApi, mapData = {}): Resource {
  const options = {
    defaultData: parse({}, { fields }),
    fields: initialState.fields,
    dataSetName,
    ...mapData,
  }
  return parse(resource, options)
}

export function getResourceTitle(resource: Resource) {
  return resource.fullName
}

function timeToMinutes(resource: ResourceApi, field: 'shiftdetail_lunch' | 'shiftdetail_break1') {
  const parts = resource[field]?.split(':')
  if (parts?.length != 3) return null
  return (+parts[0] || 0) * 60 + (+parts[1] || 0)
}

function _parseShiftStart(resource: ResourceApi) {
  const timezone = resource.current_clock_timezone || moment.tz.guess()
  const now = moment().tz(timezone)
  let shiftStart
  if (resource.shiftdetail_starthour) {
    const shiftStartArray = `${resource.shiftdetail_starthour}`.split(':')
    shiftStart = moment(now).set('hour', +shiftStartArray[0]).set('minute', +shiftStartArray[1]).set('seconds', 0)
  }

  return shiftStart
}

function _parseShiftEnd(resource: ResourceApi) {
  const timezone = resource.current_clock_timezone || moment.tz.guess()
  const now = moment().tz(timezone)
  let shiftEnd: moment.Moment | undefined
  if (resource.shiftdetail_endhour) {
    const shiftEndArray = `${resource.shiftdetail_endhour}`.split(':')
    shiftEnd = moment(now).
      set('hour', +shiftEndArray[0]).set('minute', +shiftEndArray[1]).set('seconds', 0)
  }
  const shiftStart = _parseShiftStart(resource)
  if (moment(shiftStart).isAfter(shiftEnd)) {
    shiftEnd = moment(shiftEnd).add(1, 'days')
  }

  return shiftEnd
}

export function parseIsOverTime(resource: Resource, resourceWeekInfoData: any) {
  if (!resourceWeekInfoData) return

  const timezone = resource.currentClockTimezone || moment.tz.guess()
  const now = moment().tz(timezone)
  let isOverTime = false
  if (resource.currentClockId) {
    const dayOfWeek = now.day() + 1
    const seconds = resourceWeekInfoData[`day${dayOfWeek}`]
    const defaultMaxShiftLength = 8*60*60
    if (!resource.shiftDetailId) {
      isOverTime = seconds > defaultMaxShiftLength
      return isOverTime
    }
    isOverTime = seconds > (resource.shiftLength || defaultMaxShiftLength)
  }

  return isOverTime
}

function _parseIsOutsideShiftHours(resource: ResourceApi) {
  let isOutsideShiftHours = false
  if (resource.current_clock_id) {
    const timezone = resource.current_clock_timezone || moment.tz.guess()
    const now = moment().tz(timezone)
    const shiftStart=_parseShiftStart(resource)
    const shiftEnd=_parseShiftEnd(resource)
    isOutsideShiftHours = (shiftEnd&& now.isAfter(shiftEnd)) ||
      (shiftStart && now.isBefore(shiftStart))
  }

  return isOutsideShiftHours
}

function _parseClockInDate(resource: ResourceApi) {
  if (resource.current_clock_id) {
    const timezone = resource.current_clock_timezone || moment.tz.guess()
    return moment(resource.current_clock_clockin).tz(timezone).format('DD MMM HH:mm')
  }
}

function _parseBreakRemainingTime(resource: ResourceApi) {
  if (
    resource.current_clock_id &&
    resource.current_break_id &&
    (resource.current_break_break_type === 'break' || resource.current_break_break_type === 'diner')
  ) {
    const timezone = resource.current_clock_timezone || moment.tz.guess()
    const now = moment().tz(timezone)
    const breakClockIn = resource.current_break_clockin
    const clockInDate = moment(breakClockIn).tz(timezone)
    const breakLength = resource.current_break_break_time_minutes
    return (+breakLength - Math.ceil(now.diff(clockInDate, 'minutes', true)))
  }
}

function _parseIsMissingAttendance(resource: ResourceApi) {
  if (resource.shiftdetail_id) {
    const shiftStart = _parseShiftStart(resource)
    const shiftEnd = _parseShiftEnd(resource)
    const timezone = resource.current_clock_timezone || moment.tz.guess()
    const now = moment().tz(timezone)
    return now.isBetween(shiftStart, shiftEnd)
  }
}

function _parseShiftLength(resource: ResourceApi) {
  const shiftStart=_parseShiftStart(resource)
  const shiftEnd=_parseShiftEnd(resource)
  return shiftEnd?.diff(shiftStart, 'seconds', true)
}
