import { GetFields } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { getPropertyWithFallback } from 'utils/defaultValueHelper'
import { joinOnlyStrings } from 'utils/getFirstOfType'
import { Digit, parseNumber, roundNumber } from 'utils/numberParser'
import { safeFetch } from 'utils/safeFetch'
import { convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

import { fetchContactDetails, getDefaultContactDetails } from 'reducers/contacts/contactsSlice'
import { parseReportingTag } from 'reducers/reporting-tags/reportingTagAssociationSlice'
import { parseSchedule } from 'reducers/shipments/shipmentsSlice'
import { parseTax } from 'reducers/taxes/taxesSlice'

import {
  CustomServiceConfiguration,
  MapData,
  SalesOrder,
  SalesOrderApi,
  SalesOrderGroup,
  SalesOrderGroupApi,
  SalesOrderItem,
  SalesOrderItemApi,
} from './types'

export const dataSetName = 'salesOrderView'
export const itemDataSetName = 'salesOrderItemView'
export const groupDataSetName = 'quoteGroupingRow'
export const fields = getFields()
export const lineItemFields = getLineItemFields()
export const groupFields = getGroupFields()

export const defaultSalesOrderMapData = {
  calculateStatuses: true,
}

function parseFulfillmentStatus(type: 'sales-orders' | 'sales-order-items') {
  return (props) => {
    let promisedDate: Date | null = null

    let fulfillmentStatus: string
    let status: string

    if (type === 'sales-orders') {
      promisedDate = props.calculated_min_promised_date ?
        new Date(props.calculated_min_promised_date) :
        null

      fulfillmentStatus = props.calculated_fulfillment_status
      status = props.status
    } else {
      promisedDate = props.promised_date ? new Date(props.promised_date): null

      fulfillmentStatus = props.fulfillment_status
      status = props.sales_order_status
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const isBackorder = promisedDate ? promisedDate < today : false
    const isOkStatus = !['canceled', 'closed'].includes(status)

    if (fulfillmentStatus === 'partially_fulfilled' && isBackorder && isOkStatus) {
      fulfillmentStatus += '-backorder'
    }

    return fulfillmentStatus
  }
}

type GetSalesOrderFields = GetFields<SalesOrderApi, SalesOrder, MapData, null, { quoteConfig: { apiKey: 'config'} }>
export function getFields(editOnly?: boolean, skipDuplicate?: boolean): GetSalesOrderFields {
  function parseLineCount(salesOrder) {
    return (salesOrder.lineItems || []).length
  }

  const fields: GetSalesOrderFields = {
    'id': { dataSetName, dbField: 'id', isEdit: false, type: 'id' },
    'quoteConfig': {
      dataSetName,
      dbField: 'config',
      isEdit: true,
      type: 'json',
      properties: {
        'language': { dataSetName, dbField: 'language', type: 'language', isEdit: false },
        'hideDiscount': { dataSetName, dbField: 'hideDiscount', type: 'boolean', isEdit: false },
        'hideSKU': { dataSetName, dbField: 'hideSKU', type: 'boolean', isEdit: false },
        'validUntil': { dataSetName, dbField: 'validUntil', type: 'date', isEdit: false },
        'promisedDateDisplayMode': {
          dataSetName,
          dbField: 'promisedDateDisplayMode',
          type: 'string',
          isEdit: false,
          translationNameSpace: 'salesOrders:document.',
        },
        'displayTaxColumn': { dataSetName, dbField: 'displayTaxColumn', type: 'boolean', isEdit: false },
        'displayInstructions': { dataSetName, dbField: 'displayInstructions', type: 'boolean', isEdit: false },
      },
    },
    'quoteNumber': { dataSetName, dbField: 'quote_number', isEdit: true },
    'customerId': { dataSetName, dbField: 'customer_id', isEdit: true, type: 'id', relationEntity: 'contacts' },
    'contactPersonId': {
      dataSetName,
      dbField: 'contact_person_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'contact-persons',
    },
    'referenceNumber': { dataSetName, dbField: 'reference_number', isEdit: true },
    'promisedDate': { dataSetName, dbField: 'promised_date', isEdit: true, type: 'date', isTimezoned: false },
    'desiredDate': { dataSetName, dbField: 'desired_date', isEdit: true, type: 'date', isTimezoned: false },
    'carrierId': {
      dataSetName,
      dbField: 'carrier_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'contacts',
      formDefaultValue: null,
    },
    'plantId': { dataSetName, dbField: 'plant_id', isEdit: true, type: 'id', relationEntity: 'plants' },
    'incotermId': {
      dataSetName,
      dbField: 'incoterm_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'incoterms',
      formDefaultValue: null,
    },
    'incoterm': { dataSetName, dbField: 'incoterm_code', isEdit: false },
    'incotermDetail': { dataSetName, dbField: 'incoterm_detail', isEdit: true, type: 'text', formDefaultValue: null },
    'incotermDisplayName': { dataSetName, dbField: 'incoterm_display_name', isEdit: false },
    'customerNotes': { dataSetName, dbField: 'customer_notes', isEdit: true, type: 'text' },
    'quoteNotes': { dataSetName, dbField: 'notes', isEdit: true, type: 'text' },
    'quoteConditions': { dataSetName, dbField: 'conditions', isEdit: true, type: 'text' },
    'salesOrderDate': { dataSetName, dbField: 'sales_order_date', isEdit: true, type: 'date', isTimezoned: false },
    'projectId': {
      dataSetName,
      dbField: 'project_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'projects',
      formDefaultValue: null,
    },
    'name': { dataSetName, dbField: 'name' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName, dbField: 'created_by' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'customer': { dataSetName, dbField: 'customer_display_name' },
    'customerLanguage': { dataSetName, dbField: 'customer_language', type: 'language' },
    'carrier': { dataSetName, dbField: 'carrier_display_name' },
    'projectName': { dataSetName, dbField: 'project_display_name' },
    'projectType': { dataSetName, dbField: 'project_type' },
    'plantName': { dataSetName, dbField: 'plant_name' },
    'plantCompanyName': { dataSetName, dbField: 'company_name' },
    'currencyCode': { dataSetName, dbField: 'currency_code' },
    'currencySymbol': { dataSetName, dbField: 'currency_symbol' },
    'exchangeRate': { dataSetName, dbField: 'snapped_exchange_rate', type: 'float' },
    'status': {
      dataSetName,
      dbField: 'status',
      customEventValueTranslationKey: (value) => `salesOrders:status.${value ?? 'draft'}`,
    },
    'shippedSum': { dataSetName, dbField: 'shipped_sum', dataSetAlias: 'calculated' },
    'measureSum': { dataSetName, dbField: 'measure_sum', dataSetAlias: 'calculated' },
    'invoicedSum': { dataSetName, dbField: 'invoiced_sum', dataSetAlias: 'calculated' },
    'fulfillmentStatus': {
      dataSetName,
      dbField: 'fulfillment_status',
      dataSetAlias: 'calculated',
      parse: parseFulfillmentStatus('sales-orders'),
    },
    'invoiceStatus': { dataSetName, dbField: 'invoice_status', dataSetAlias: 'calculated' },
    'paymentStatus': { dataSetName, dbField: 'calculated_status', dataSetAlias: 'payment' },
    'externalNumber': { dataSetName, dbField: 'external_number' },
    'externalCouponCodes': { dataSetName, dbField: 'external_coupon_codes' },
    'zohoCrmDealId': { dataSetName, dbField: 'zoho_crm_deal_id', type: 'id' },
    'zohoCrmDealModule': { dataSetName, dbField: 'zoho_crm_deal_module' },
    'wooCommerceId': { dataSetName, dbField: 'woo_commerce_order_id', type: 'id' },
    'shipmentNumberAggregate': { dataSetName, dbField: 'shipment_number_aggregate' },
    'lineCount': { parse: parseLineCount },
    'lineItems': { parse: (salesOrder, options) => {
      const isPrimaryLanguage = options?.primaryLanguage === salesOrder?.customer_language
      const documentLanguage = salesOrder?.config?.language || salesOrder?.customer_language
      const isDocumentPrimaryLanguage = options?.primaryLanguage === documentLanguage

      const lineItems = (salesOrder?.lineItems || []).map((lineItem) => parseSalesOrderLineItem(
        lineItem,
        {
          defaultUnits: options?.defaultUnits,
          isPrimaryLanguage,
          isDocumentPrimaryLanguage,
          taxDict: options?.taxDict,
          priceMaxDigits: options?.priceMaxDigits,
          measureMaxDigits: options?.measureMaxDigits,
        },
      ))
      const groups = (salesOrder?.groups || []).map((group) => parseSalesOrderGroup(group))
      return [...lineItems, ...groups]
    } },
    // TODO (odeschenes): Remove these fields when we move out of the object fields in the GetFields.
    'shipTo.id': {
      dataSetName,
      dbField: 'ship_to_address_id',
      type: 'id',
      relationEntity: 'addresses',
    },
    'shipTo.attention': { dataSetName, dbField: 'ship_to_attention' },
    'shipTo.street': { dataSetName, dbField: 'ship_to_address' },
    'shipTo.street2': { dataSetName, dbField: 'ship_to_street2' },
    'shipTo.city': { dataSetName, dbField: 'ship_to_city' },
    'shipTo.state': { dataSetName, dbField: 'ship_to_state' },
    'shipTo.zip': { dataSetName, dbField: 'ship_to_zip' },
    'shipTo.country': { dataSetName, dbField: 'ship_to_country' },
    'shipTo.phone': { dataSetName, dbField: 'ship_to_phone' },

    'shipToAttention': { dataSetName, dbField: 'ship_to_attention' },
    'shipToStreet': { dataSetName, dbField: 'ship_to_address' },
    'shipToStreet2': { dataSetName, dbField: 'ship_to_street2' },
    'shipToCity': { dataSetName, dbField: 'ship_to_city' },
    'shipToState': { dataSetName, dbField: 'ship_to_state' },
    'shipToZip': { dataSetName, dbField: 'ship_to_zip' },
    'shipToCountry': { dataSetName, dbField: 'ship_to_country' },
    'shipToPhone': { dataSetName, dbField: 'ship_to_phone' },

    'billTo.id': { dataSetName, dbField: 'bill_to_address_id', type: 'id', relationEntity: 'addresses' },
    'billTo.attention': { dataSetName, dbField: 'bill_to_attention' },
    'billTo.street': { dataSetName, dbField: 'bill_to_address' },
    'billTo.street2': { dataSetName, dbField: 'bill_to_street2' },
    'billTo.city': { dataSetName, dbField: 'bill_to_city' },
    'billTo.state': { dataSetName, dbField: 'bill_to_state' },
    'billTo.zip': { dataSetName, dbField: 'bill_to_zip' },
    'billTo.country': { dataSetName, dbField: 'bill_to_country' },
    'billTo.phone': { dataSetName, dbField: 'bill_to_phone' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean', isEdit: false },
    'companyId': { dataSetName, dbField: 'company_id', type: 'id', isEdit: false },
    'currencyId': { dataSetName, dbField: 'currency_id', type: 'id', isEdit: false },
    'customerZohoBooksContactId': { dataSetName, dbField: 'customer_zoho_books_contact_id', type: 'id', isEdit: false },
    'externalId': { dataSetName, dbField: 'external_id', type: 'id', isEdit: false },
    'ownerId': { dataSetName, dbField: 'owner_id', type: 'id', isEdit: false, relationEntity: 'resources' },
    'priceListId': { dataSetName, dbField: 'price_list_id', type: 'id', isEdit: false },
    'zohoBooksEstimateId': { dataSetName, dbField: 'zoho_books_estimate_id', type: 'id', isEdit: false },
    'zohoBooksSoId': { dataSetName, dbField: 'zoho_books_so_id', type: 'id', isEdit: false },
    'billToStateCode': { dataSetName, dbField: 'bill_to_state_code', type: 'string', isEdit: false },
    'plantCode': { dataSetName, dbField: 'plant_code', type: 'string', isEdit: false },
    'priceListName': { dataSetName, dbField: 'price_list_name', type: 'string', isEdit: false },
    'projectStatus': { dataSetName, dbField: 'project_status', type: 'string', isEdit: false },
    'projectTitle': { dataSetName, dbField: 'project_title', type: 'string', isEdit: false },
    'shipToStateCode': { dataSetName, dbField: 'ship_to_state_code', type: 'string', isEdit: false },
    'emailingDetails': { dataSetName, dbField: 'emailing_details', type: 'json', isEdit: false },

    'cid': { dataSetName, dbField: 'cid', isEdit: false },
    'generatedNumber': { dataSetName, dbField: 'generated_number', isEdit: false },
    'customerCompanyName': { dataSetName, dbField: 'customer_company_name', isEdit: false },
    'carrierCompanyName': { dataSetName, dbField: 'carrier_company_name', isEdit: false },
    'contactCarrierAccountId': {
      dataSetName,
      dbField: 'contact_carrier_account_id',
      type: 'id',
      relationEntity: 'contact-carrier-accounts',
      formDefaultValue: null,
      customFieldTranslationKey: (t) => t('contacts:contactCarrierAccount.fields.contactCarrierAccount.label'),
      isEdit: true,
    },
    'contactCarrierAccountDescription': { dataSetName, dbField: 'contact_carrier_account_description', isEdit: false },
    'contactCarrierAccountDisplayName': { dataSetName, dbField: 'contact_carrier_account_display_name', isEdit: false },
    'contactCarrierAccountCode': { dataSetName, dbField: 'contact_carrier_account_code', isEdit: false },
    'salespersonId': { dataSetName, dbField: 'salesperson_id', type: 'id', relationEntity: 'contacts' },
    'salespersonName': { dataSetName, dbField: 'salesperson_name' },
    'salespersonEmail': { dataSetName, dbField: 'salesperson_email' },
    'salespersonPhone': { dataSetName, dbField: 'salesperson_phone' },
  }

  let fieldsToReturn = Object.keys(fields)
  let _toSkip = []
  if (skipDuplicate) {
    _toSkip = ['referenceNumber', 'promisedDate', 'desiredDate', 'quoteConfig']
  } else if (editOnly) {
    const _toInclude = [
      'id', 'quoteConfig', 'customerId', 'contactPersonId',
      'referenceNumber', 'promisedDate', 'desiredDate',
      'carrierId', 'plantId', 'incotermId', 'customerNotes',
      'quoteNotes', 'quoteConditions', 'salesOrderDate',
      'projectId', 'quoteNumber', 'contactCarrierAccountId',
      'incotermDetail', 'salespersonId',
    ]
    fieldsToReturn = Object.keys(fields).filter((key) => _toInclude.includes(key))
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    if (_toSkip.includes(key)) {
      newAcc[key].skip = true
    }
    return newAcc
  }, {})
}

export function parseItemTaxObj(item: SalesOrderItemApi, options: MapData) {
  const taxObj = { total: 0 }
  if (!options?.taxDict || !item?.is_taxed) {
    return taxObj
  }

  const tax = options.taxDict[item.tax_id]
  if (!tax) {
    return taxObj
  }

  const totalPrice = parseTotalPrice(
    item?.unit_price,
    item?.dimension_to_display,
    item?.measure_unit,
    item?.measure,
    item?.discount,
    { ...options },
  )

  if (tax.taxType === 'tax_group') {
    tax.taxes.forEach((tax) => {
      taxObj[tax.displayName] = +((totalPrice * tax.taxPercentage) / 100).toFixed(2)
      taxObj.total += taxObj[tax.displayName]
    })
  } else {
    taxObj[tax.displayName] = +((totalPrice * tax.taxPercentage) / 100).toFixed(2)
    taxObj.total += taxObj[tax.displayName]
  }

  return taxObj
}

export function parseTotalPrice(
  unitPrice: number,
  dimension: string,
  unitMeasure: string,
  measure: number,
  discount: number,
  options: MapData,
) {
  const unitPriceConverted = roundNumber(convertFromDollarPerBase(
    dimension,
    unitPrice,
    unitMeasure,
  ), options?.priceMaxDigits || 2)
  const measureConverted = roundNumber(convertFromBase(
    dimension,
    measure,
    unitMeasure,
    true,
  ), options?.measureMaxDigits || 2)
  const discountCalculated = (+discount || 0)/100
  return +roundNumber((unitPriceConverted*measureConverted*(1-discountCalculated)), 2)
}

export function getDefaultSalesOrder() {
  return {
    ...parse({}, { fields }),
    customerDetails: getDefaultContactDetails(),
  }
}

export function parseSalesOrder(salesOrder: SalesOrderApi, mapData?: MapData, overriddenFields?: GetFields) {
  function parseSubtotal(lineItems = []) {
    return lineItems.reduce((acc, item) => acc + (+item.totalPrice || 0), 0)
  }

  const options = {
    ...mapData,
    defaultData: getDefaultSalesOrder(),
    fields: overriddenFields ?? fields,
    dataSetName,
  }

  const parsedSalesOrder = parse(salesOrder, options)
  parsedSalesOrder.subtotal = parseSubtotal(parsedSalesOrder.lineItems)
  parsedSalesOrder.taxObj = parseTaxObj(parsedSalesOrder.lineItems)
  parsedSalesOrder.total = parsedSalesOrder.subtotal + (+parsedSalesOrder.taxObj.total || 0)
  return parsedSalesOrder
}

export function parseTaxObj(items = []) {
  return items.reduce((acc, item) => {
    Object.keys(item.taxObj || {}).forEach((key) => {
      if (!acc[key]) {
        acc[key] = item.taxObj[key]
      } else {
        acc[key] += item.taxObj[key]
      }
    })
    return acc
  }, {})
}

type GetItemFields = GetFields<
  SalesOrderItemApi,
  SalesOrderItem,
  MapData,
  null,
  {
    customServiceConfiguration: {
      apiKey: 'customServiceConfiguration',
      jsonMatcher: { finishedGoodTemplate: { apiKey: 'finishedGoodTemplate' } },
    },
  }
>
export function getLineItemFields(editOnly?: boolean, skipDuplicate?: boolean): GetItemFields {
  function _parseItemName(item: SalesOrderItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_name || (
      isPrimaryLanguage ? item?.primary_name || item?.secondary_name : item?.secondary_name || item?.primary_name
    )
  }

  function parseItemName(item: SalesOrderItemApi, options: MapData) {
    return _parseItemName(item, options?.isPrimaryLanguage)
  }

  function parseItemDocumentName(item: SalesOrderItemApi, options: MapData) {
    return _parseItemName(item, options?.isDocumentPrimaryLanguage)
  }

  function _attributeToDisplayFormat(item: SalesOrderItemApi, isPrimaryLanguage: boolean) {
    const attributeDescription = getPropertyWithFallback(
      item,
      'attribute_description',
      'attribute_secondary_description',
      isPrimaryLanguage,
    )
    const attributeOption = getPropertyWithFallback(
      item,
      'attribute_option_display_description',
      'attribute_option_display_secondary_description',
      isPrimaryLanguage,
    )

    if (!attributeDescription || !attributeOption) {
      return null
    }

    return `${attributeDescription}: ${attributeOption}`
  }

  function parseItemAttributeDisplay(item: SalesOrderItemApi, options: MapData) {
    return _attributeToDisplayFormat(item, options?.isPrimaryLanguage)
  }

  function parseItemDocumentAttribute(item: SalesOrderItemApi, options: MapData) {
    return _attributeToDisplayFormat(item, options?.isDocumentPrimaryLanguage)
  }

  function getMeasureUnit(item: SalesOrderItemApi, options: MapData) {
    const dimension = item?.dimension_to_display
    return item?.measure_unit || options?.defaultUnits?.[dimension]
  }

  function getWeightUnit(item: SalesOrderItemApi, options: MapData) {
    return item?.dimension_to_display === 'weight' ?
      getMeasureUnit(item, options) :
      (item?.template_weight_unit || options?.defaultUnits?.weight)
  }

  function parseQtyPer() {
    return 1
  }

  function _parseItemNotes(item: SalesOrderItemApi, isPrimaryLanguage: boolean) {
    return item?.overwritten_notes || (
      isPrimaryLanguage ? item?.primary_notes || item?.secondary_notes : item?.secondary_notes || item?.primary_notes
    )
  }

  function parseItemNotes(item: SalesOrderItemApi, options: MapData) {
    return _parseItemNotes(item, options?.isPrimaryLanguage)
  }

  function parseItemDocumentNotes(item: SalesOrderItemApi, options: MapData) {
    return _parseItemNotes(item, options?.isDocumentPrimaryLanguage)
  }

  function parseCustomServiceConfiguration(item: SalesOrderItem): CustomServiceConfiguration {
    return {
      manufacturingOrderTemplateId: item.manufacturingOrderId || item.templateManufacturingOrderId,
      attributeOptionId: item.attributeOptionId,
      finishedGoodTemplateId: '',
      finishedGoodTemplate: {
        id: null,
        description: null,
        secondaryDescription: null,
        isManufactured: null,
        isSelling: null,
        isPurchased: null,
        endVSInfo: {},
        groupId: null,
        type: null,
        dimension: null,
        measureUnit: null,
        plantId: null,
        inventoryManagementType: null,
        sku: null,
        minSellingMeasure: 0,
        associatedRawTemplateId: null,
        configurableTemplateId: null,
        configurableTemplateSku: null,
        configurableTemplateDescription: null,
        configurableTemplateSecondaryDescription: null,
      },
      customerConsignmentsForm: { selections: [], insertions: {}, updates: {}, deletions: {} },
      associatedRawTemplateId: '',
      salesOrderItemId: item.id,
      finishedGoodRadioOption: null,
      moq: null,
    }
  }

  const fields: GetItemFields = {
    'displayTitle': {
      parseWithParsedData: (item) => joinOnlyStrings([item?.salesOrderName, item?.name], ' - '),
      dataSetName: itemDataSetName,
      dbField: 'sales_order_name',
    },
    'id': { dataSetName: itemDataSetName, dbField: 'id', isEdit: false, type: 'id' },
    'discounted': { dataSetName: itemDataSetName, dbField: 'discounted_unit_price', isEdit: false, type: 'currency' },

    'isTaxed': { dataSetName: itemDataSetName, dbField: 'is_taxed', isEdit: false, type: 'boolean' },
    'clientTaxId': {
      dataSetName: itemDataSetName,
      dbField: 'client_tax_id',
      isEdit: false,
      type: 'id',
      relationEntity: 'taxes',
    },
    'clientTax': { parse: (item) => item.client_tax ? parseTax(item.client_tax) : null },
    'taxId': { dataSetName: itemDataSetName, dbField: 'tax_id', isEdit: true, type: 'id', relationEntity: 'taxes' },

    'name': { dataSetName: itemDataSetName, dbField: 'overwritten_name', isEdit: true, parse: parseItemName },
    'overwrittenName': { dataSetName: itemDataSetName, dbField: 'overwritten_name', isEdit: true },
    'unitPrice': {
      dataSetName: itemDataSetName,
      updateDbField: 'unit_selling_price',
      dbField: 'unit_price',
      isEdit: true,
      formDefaultValue: 0,
      type: 'currency',
    },
    'quantity': {
      dataSetName: itemDataSetName,
      dbField: 'measure',
      isEdit: true,
      formDefaultValue: 0,
      type: 'measure',
    },
    'discount': {
      dataSetName: itemDataSetName,
      dbField: 'discount',
      isEdit: true,
      formDefaultValue: 0,
      type: 'percentage',
    },
    'notes': {
      dataSetName: itemDataSetName,
      dbField: 'overwritten_notes',
      isEdit: true,
      type: 'text',
      parse: parseItemNotes,
    },
    'overwrittenNotes': { dataSetName: itemDataSetName, dbField: 'overwritten_notes', isEdit: true, type: 'text' },
    'primaryNotes': { dataSetName: itemDataSetName, dbField: 'primary_notes' },
    'secondaryNotes': { dataSetName: itemDataSetName, dbField: 'secondary_notes' },
    'contractNumber': { dataSetName: itemDataSetName, dbField: 'contract_number', isEdit: true },
    'promisedDate': {
      dataSetName: itemDataSetName,
      dbField: 'promised_date',
      isEdit: true,
      type: 'date',
      isTimezoned: false,
    },
    'rank': { dataSetName: itemDataSetName, dbField: 'sales_ranking', isEdit: true, type: 'integer' },
    'templateId': { dataSetName: itemDataSetName, dbField: 'template_id', isEdit: true, type: 'id' },
    'dimension': { dataSetName: itemDataSetName, dbField: 'dimension_to_display' },
    'unit': { dataSetName: itemDataSetName, dbField: 'measure_unit', parse: getMeasureUnit },
    'weightUnit': {
      dataSetName: itemDataSetName,
      dbField: 'template_weight_unit',
      parse: getWeightUnit,
      type: 'measure',
    },
    'sku': { dataSetName: itemDataSetName, dbField: 'template_sku' },
    'moq': { parse: () => null, type: 'float' },
    'manufacturingOrderFormattedNumber': {
      dataSetName: itemDataSetName, dbField: 'manufacturing_order_formated_number',
    },
    'itemCount': { dataSetName: itemDataSetName, dbField: 'item_count', type: 'integer' },
    'manufacturingOrderProjectId': {
      dataSetName: itemDataSetName, dbField: 'manufacturing_order_project_id', type: 'id', relationEntity: 'projects',
    },
    'description': {
      dataSetName: itemDataSetName,
      dbField: 'primary_name',
      customFieldTranslationKey: (t: Function, options) =>
        t(`salesOrders:salesOrder.item.name`, { language: options?.primaryLanguage }),
    },
    'isPurchased': {
      dataSetName: itemDataSetName,
      dbField: 'template_is_purchased',
      type: 'boolean',
      isEdit: false,
    },
    'isSelling': {
      dataSetName: itemDataSetName,
      dbField: 'template_is_selling',
      type: 'boolean',
      isEdit: false,
    },
    'isManufactured': { dataSetName: itemDataSetName, dbField: 'template_is_manufactured' },
    'manufacturingOrderId': { parse: (item) => {
      return item?.template_id ? item?.template_manufacturing_order_id : item?.manufacturing_order_id
    }, type: 'id' },
    'netWeight': {
      dataSetName: itemDataSetName,
      dbField: 'weight_net',
      type: 'measure',
      customFormattedValue: (value, { measureDigits, culture }, entityData) => {
        return parseSalesOrderItemNetWeight(
          entityData.quantity,
          entityData,
          {
            measureDigits,
            culture,
          },
        )
      },
    },
    'netWeightUnit': { dataSetName: itemDataSetName, dbField: 'weight_net_unit' },
    'treatment': { dataSetName: itemDataSetName, dbField: 'treatment_title' },
    'rawMetric': { dataSetName: itemDataSetName, dbField: 'raw_metric_title' },
    'rawImperial': { dataSetName: itemDataSetName, dbField: 'raw_imperial_title' },
    'unitWeight': { dataSetName: itemDataSetName, dbField: 'template_unit_weight', type: 'float' },
    'plannedUnitCost': { dataSetName: itemDataSetName, dbField: 'planned_unit_cost', type: 'currency' },
    'templateInventoryManagementType': {
      dataSetName: itemDataSetName,
      dbField: 'template_inventory_management_type',
    },
    'clientZohoId': { dataSetName: itemDataSetName, dbField: 'client_zoho_books_id', type: 'id' },
    'clientZohoBillToAddressId': {
      dataSetName: itemDataSetName,
      dbField: 'project_bill_to_address_zoho_books_id',
      type: 'id',
    },
    'accountId': {
      dataSetName: itemDataSetName,
      dbField: 'account_id',
      type: 'id',
      isEdit: true,
      relationEntity: 'chart-of-accounts',
    },
    'accountExternalId': { dataSetName: itemDataSetName, dbField: 'account_external_id', type: 'id' },
    'unitSellingPrice': { dataSetName: itemDataSetName, dbField: 'unit_selling_price', type: 'currency' },
    'templateTitle': { dataSetName: itemDataSetName, dbField: 'template_title' },
    'qtyPer': { parse: parseQtyPer },
    'totalPrice': { parse: (item, options) => parseTotalPrice(
      item?.unit_price,
      item?.dimension_to_display,
      item?.measure_unit,
      item?.measure,
      item?.discount,
      options,
    ), type: 'currency' },
    'taxObj': { parse: parseItemTaxObj },
    'documentName': { parse: parseItemDocumentName },
    'documentNotes': { parse: parseItemDocumentNotes },
    'shipments': { dataSetName: itemDataSetName, dbField: 'shipmentList' },
    'shipped': { dataSetName: itemDataSetName, dbField: 'shipped_count', type: 'measure' },
    'reserved': { dataSetName: itemDataSetName, dbField: 'active_planned_ledger_planned_measure_sum', type: 'float' },
    'inStock': {
      dataSetName: itemDataSetName, dbField: 'active_planned_measure_sum_ready', type: 'float',
    },
    'toReceive': {
      dataSetName: itemDataSetName, dbField: 'active_planned_measure_sum_to_receive', type: 'float',
    },
    'toManufacture': {
      dataSetName: itemDataSetName, dbField: 'active_planned_measure_sum_to_manufacture', type: 'float',
    },
    'invoiced': { dataSetName: itemDataSetName, dbField: 'invoiced_count', type: 'measure' },
    'resolvedReserved': {
      dataSetName: itemDataSetName, dbField: 'resolved_planned_ledger_planned_measure_sum', type: 'measure',
    },
    'toReserve': { parse: (item) => {
      const reserved = +item?.active_planned_ledger_planned_measure_sum || 0
      const resolvedReserved = +item?.resolved_planned_ledger_planned_measure_sum || 0
      const quantity = +item?.measure || 0
      return Math.max(quantity - reserved - resolvedReserved, 0)
    } },
    'createdDate': { dataSetName: itemDataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName: itemDataSetName, dbField: 'created_by' },
    'createdById': { dataSetName: itemDataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName: itemDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: itemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: itemDataSetName, dbField: 'modified_by_id', type: 'id' },

    'exist': { dataSetName: itemDataSetName, dbField: 'exist', type: 'boolean' },
    'isAssignedToShipment': { dataSetName: itemDataSetName, dbField: 'is_assigned_to_shipment', type: 'boolean' },
    'isInvoiced': { dataSetName: itemDataSetName, dbField: 'is_invoiced', type: 'boolean' },
    'projectExist': { dataSetName: itemDataSetName, dbField: 'project_exist', type: 'boolean' },
    'salesOrderExist': { dataSetName: itemDataSetName, dbField: 'sales_order_exist', type: 'boolean' },
    'showInSales': { dataSetName: itemDataSetName, dbField: 'show_in_sales', type: 'boolean' },

    'invoiceDate': { dataSetName: itemDataSetName, dbField: 'invoice_date', type: 'date' },
    'computedUnitPrice': { dataSetName: itemDataSetName, dbField: 'computed_unit_price', type: 'float' },
    'convertedComputedUnitPrice': {
      dataSetName: itemDataSetName, dbField: 'converted_computed_unit_price', type: 'currency',
    },
    'equipmentPlannedCost': { dataSetName: itemDataSetName, dbField: 'equipment_planned_cost', type: 'currency' },
    'equipmentPricing': { dataSetName: itemDataSetName, dbField: 'equipment_pricing', type: 'float' },
    'materialPlannedCost': { dataSetName: itemDataSetName, dbField: 'material_planned_cost', type: 'currency' },
    'materialPricing': { dataSetName: itemDataSetName, dbField: 'material_pricing', type: 'float' },
    'measureAssignedToShipment': {
      dataSetName: itemDataSetName, dbField: 'measure_assigned_to_shipment', type: 'measure',
    },
    'measureRemainingToShip': { dataSetName: itemDataSetName, dbField: 'measure_remaining_to_ship', type: 'measure' },
    'resourcePlannedCost': { dataSetName: itemDataSetName, dbField: 'resource_planned_cost', type: 'float' },
    'resourcePricing': { dataSetName: itemDataSetName, dbField: 'resource_pricing', type: 'currency' },
    'salesExchangeRate': { dataSetName: itemDataSetName, dbField: 'sales_exchange_rate', type: 'float' },
    'snappedExchangeRate': { dataSetName: itemDataSetName, dbField: 'snapped_exchange_rate', type: 'float' },
    'subcontractPlannedCost': { dataSetName: itemDataSetName, dbField: 'subcontract_planned_cost', type: 'currency' },
    'subcontractPricing': { dataSetName: itemDataSetName, dbField: 'subcontract_pricing', type: 'float' },
    'templateToleranceOverFactor': {
      dataSetName: itemDataSetName, dbField: 'template_tolerance_over_factor', type: 'float',
    },
    'templateToleranceUnderFactor': {
      dataSetName: itemDataSetName, dbField: 'template_tolerance_under_factor', type: 'float',
    },
    'weightTare': { dataSetName: itemDataSetName, dbField: 'weight_tare', type: 'float' },

    'customerId': { dataSetName: itemDataSetName, dbField: 'customer_id', type: 'id', isEdit: false },
    'expenseAccountId': { dataSetName: itemDataSetName, dbField: 'expense_account_id', type: 'id', isEdit: false },
    'invoiceExternalId': { dataSetName: itemDataSetName, dbField: 'invoice_external_id', type: 'id', isEdit: false },
    'manufacturingOrderCurrentStepId': {
      dataSetName: itemDataSetName, dbField: 'manufacturing_order_current_step_id', type: 'id', isEdit: false,
    },
    'projectId': { dataSetName: itemDataSetName, dbField: 'project_id', type: 'id', isEdit: false },
    'salesOrderId': {
      dataSetName: itemDataSetName,
      dbField: 'sales_order_id',
      type: 'id',
      relationEntity: 'sales-orders',
      isEdit: false,
    },
    'plantId': {
      dataSetName: itemDataSetName,
      dbField: 'plant_id',
      isEdit: false,
      type: 'id',
      relationEntity: 'plants',
    },
    'salesOrderReferenceNumber': {
      dataSetName: itemDataSetName,
      dbField: 'sales_order_reference_number',
      isEdit: false,
      type: 'string',
    },
    'salesOrderPromisedDate': {
      dataSetName: itemDataSetName,
      dbField: 'sales_order_promised_date',
      isEdit: false,
      type: 'date',
    },
    'shipmentId': {
      dataSetName: itemDataSetName,
      dbField: 'shipment_id',
      type: 'id',
      relationEntity: 'shipments',
      isEdit: false,
    },
    'schedule': { parse: (item, options: MapData) => parseSchedule(item?.schedule, options) },
    'templateManufacturingOrderId': {
      dataSetName: itemDataSetName, dbField: 'template_manufacturing_order_id', type: 'id', isEdit: false,
    },
    'manufacturingOrderStatus': {
      dataSetName: itemDataSetName, dbField: 'manufacturing_order_status', type: 'integer', isEdit: false,
    },
    'categoryTitle': { dataSetName: itemDataSetName, dbField: 'category_title', type: 'string', isEdit: false },
    'clientCompanyName': {
      dataSetName: itemDataSetName, dbField: 'client_company_name', type: 'string', isEdit: false,
    },
    'clientDisplayName': {
      dataSetName: itemDataSetName, dbField: 'client_display_name', type: 'string', isEdit: false,
    },
    'currencyCode': { dataSetName: itemDataSetName, dbField: 'currency_code', type: 'string', isEdit: false },
    'currencySymbol': { dataSetName: itemDataSetName, dbField: 'currency_symbol', type: 'string', isEdit: false },
    'invoiceNumber': { dataSetName: itemDataSetName, dbField: 'invoice_number', type: 'string', isEdit: false },
    'materialTitle': { dataSetName: itemDataSetName, dbField: 'material_title', type: 'string', isEdit: false },
    'partNumber': { dataSetName: itemDataSetName, dbField: 'part_number', type: 'string', isEdit: false },
    'projectStatus': { dataSetName: itemDataSetName, dbField: 'project_status', type: 'string', isEdit: false },
    'projectTitle': { dataSetName: itemDataSetName, dbField: 'project_title', type: 'string', isEdit: false },
    'projectType': { dataSetName: itemDataSetName, dbField: 'project_type', type: 'string', isEdit: false },
    'salesCurrencyCode': {
      dataSetName: itemDataSetName, dbField: 'sales_currency_code', type: 'string', isEdit: false,
    },
    'salesOrderName': { dataSetName: itemDataSetName, dbField: 'sales_order_name', type: 'string', isEdit: false },
    'secondaryName': {
      dataSetName: itemDataSetName,
      dbField: 'secondary_name',
      type: 'string',
      isEdit: false,
      customFieldTranslationKey: (t: Function, options: MapData) =>
        t(`salesOrders:salesOrder.item.name`, { language: options?.secondaryLanguage }),
    },
    'shipmentNumber': { dataSetName: itemDataSetName, dbField: 'shipment_number', type: 'string', isEdit: false },
    'templatePromptExtraConsignment': {
      dataSetName: itemDataSetName, dbField: 'template_prompt_extra_consignment', type: 'boolean', isEdit: false,
    },
    'templateAttributeId': {
      dataSetName: itemDataSetName,
      dbField: 'template_attribute_id',
      type: 'id',
      relationEntity: 'attributes',
      isEdit: false,
    },
    'templateDescription': {
      dataSetName: itemDataSetName, dbField: 'template_description', type: 'string', isEdit: false,
    },
    'templateManufacturer': {
      dataSetName: itemDataSetName, dbField: 'template_manufacturer', type: 'string', isEdit: false,
    },
    'templatePartNumber': {
      dataSetName: itemDataSetName, dbField: 'template_part_number', type: 'string', isEdit: false,
    },
    'templateRevision': { dataSetName: itemDataSetName, dbField: 'template_revision', type: 'string', isEdit: false },
    'templateSecondaryDescription': {
      dataSetName: itemDataSetName, dbField: 'template_secondary_description', type: 'string', isEdit: false,
    },
    'templateUnitSellingPrice': {
      dataSetName: itemDataSetName, dbField: 'template_unit_selling_price', type: 'string', isEdit: false,
    },
    'templateUnit': { dataSetName: itemDataSetName, dbField: 'template_measure_unit', isEdit: false },
    'templateDimension': { dataSetName: itemDataSetName, dbField: 'template_dimension_to_display', isEdit: false },
    'type': { dataSetName: itemDataSetName, dbField: 'manufacturing_order_type', type: 'string', isEdit: false },
    'consignmentItemCount': { dataSetName: itemDataSetName, dbField: 'consignment_item_count', type: 'integer' },
    'consignmentMode': { dataSetName: itemDataSetName, dbField: 'consignment_mode', type: 'string' },
    'reportingTagsCount': { parse: (soItem) => soItem.reportingTags?.length || 0 },
    'reportingTags': {
      dataSetName: itemDataSetName,
      dbField: 'reportingTags',
      type: 'array',
      parse: (soItem) =>
        soItem.reportingTags?.map((reportingTag) => parseReportingTag('sales-order-items', reportingTag)) ?? [],
    },
    'customServiceConfiguration': {
      // ! TODO (lleduc): À voir si on remove la combinaison dataSetName/dbField pour juste updateDbField
      dataSetName: itemDataSetName,
      dbField: 'customServiceConfiguration',
      type: 'json',
      parseWithParsedData: parseCustomServiceConfiguration,
      isParseProperties: true,
      properties: {
        'associatedRawTemplateId': {
          dataSetName: itemDataSetName,
          dbField: 'associated_raw_template_id',
          type: 'id',
          relationEntity: 'items',
        },
        'manufacturingOrderTemplateId': {
          dataSetName: itemDataSetName,
          dbField: 'manufacturing_order_template_id',
          type: 'id',
          relationEntity: 'cards',
        },
        'attributeOptionId': {
          dataSetName: itemDataSetName,
          dbField: 'attribute_option_id',
          type: 'id',
          relationEntity: 'attribute-options',
        },
        'finishedGoodTemplateId': {
          dataSetName: itemDataSetName,
          dbField: 'finished_good_template_id',
          type: 'id',
          relationEntity: 'items',
          xor: 'finishedGoodTemplate',
        },
        'finishedGoodTemplate': {
          // ! TODO (lleduc): À voir si on remove la combinaison dataSetName/dbField pour juste updateDbField
          dataSetName: itemDataSetName,
          dbField: 'finishedGoodTemplate',
          type: 'json',
          isParseProperties: true,
          properties: {
            'id': { dataSetName: itemDataSetName, dbField: 'id', type: 'id', isEdit: true },
            'description': { dataSetName: itemDataSetName, dbField: 'description', type: 'string', isEdit: true },
            'secondaryDescription': {
              dataSetName: itemDataSetName,
              dbField: 'secondary_description',
              type: 'string',
              isEdit: true,
            },
            'isManufactured': {
              dataSetName: itemDataSetName,
              dbField: 'is_manufactured',
              type: 'boolean',
              isEdit: true,
            },
            'endVSInfo': { dataSetName: itemDataSetName, dbField: 'endVSInfo', type: 'json', isEdit: true },
            'isPurchased': { dataSetName: itemDataSetName, dbField: 'is_purchased', type: 'boolean', isEdit: true },
            'isSelling': { dataSetName: itemDataSetName, dbField: 'is_selling', type: 'boolean', isEdit: true },
            'groupId': { dataSetName: itemDataSetName, dbField: 'group_id', type: 'id', isEdit: true },
            'type': { dataSetName: itemDataSetName, dbField: 'type', type: 'string', isEdit: true },
            'dimension': {
              dataSetName: itemDataSetName,
              dbField: 'dimension_to_display',
              type: 'string',
              isEdit: true,
            },
            'measureUnit': { dataSetName: itemDataSetName, dbField: 'measure_unit', type: 'string', isEdit: true },
            'plantId': {
              dataSetName: itemDataSetName,
              dbField: 'plant_id',
              type: 'id',
              relationEntity: 'plants',
              isEdit: true,
            },
            'inventoryManagementType': {
              dataSetName: itemDataSetName,
              dbField: 'inventory_management_type',
              type: 'string',
              isEdit: true,
            },
            'minSellingMeasure': {
              dataSetName: itemDataSetName,
              dbField: 'min_selling_measure',
              formDefaultValue: 0,
              type: 'float',
              isEdit: true,
            },
            'sku': { dataSetName: itemDataSetName, dbField: 'sku', type: 'string', isEdit: true },
            'associatedRawTemplateId': {
              dataSetName: itemDataSetName,
              dbField: 'associated_raw_template_id',
              type: 'id',
              relationEntity: 'items',
              isEdit: true,
            },
            'configurableTemplateId': {
              dataSetName: itemDataSetName,
              dbField: 'configurable_template_id',
              type: 'id',
              relationEntity: 'items',
              isEdit: true,
            },
            'configurableTemplateSku': {
              dataSetName: itemDataSetName,
              dbField: 'configurable_template_sku',
              type: 'string',
              isEdit: true,
            },
            'configurableTemplateDescription': {
              dataSetName: itemDataSetName,
              dbField: 'configurable_template_description',
              type: 'string',
              isEdit: true,
            },
            'configurableTemplateSecondaryDescription': {
              dataSetName: itemDataSetName,
              dbField: 'configurable_template_secondary_description',
              type: 'string',
              isEdit: true,
            },
          },
        },
        'customerConsignmentsForm': {
          parse: () => ({ selections: [], insertions: {}, updates: {}, deletions: {} }),
          type: 'json',
        },
        'salesOrderItemId': {
          dataSetName: itemDataSetName,
          dbField: 'sales_order_item_id',
          type: 'id',
          relationEntity: 'sales-order-items',
        },
        'finishedGoodRadioOption': { parse: () => null, type: 'string' },
        'moq': { parse: () => null, type: 'float' },
      },
    },
    'attributeId': {
      dataSetName: itemDataSetName,
      dbField: 'attribute_id',
      type: 'id',
      relationEntity: 'attributes',
      isEdit: false,
    },
    'attributeOptionId': {
      dataSetName: itemDataSetName,
      dbField: 'attribute_option_id',
      type: 'id',
      relationEntity: 'attribute-options',
      isEdit: false,
    },
    'customServiceTemplateId': {
      dataSetName: itemDataSetName,
      dbField: 'custom_service_template_id',
      type: 'id',
      relationEntity: 'items',
      isEdit: true,
    },
    'attributeDescription': { dataSetName: itemDataSetName, dbField: 'attribute_description', isEdit: false },
    'attributeSecondaryDescription': {
      dataSetName: itemDataSetName,
      dbField: 'attribute_secondary_description',
      isEdit: false,
    },
    'attributeOptionDescription': {
      dataSetName: itemDataSetName,
      dbField: 'attribute_option_description',
      isEdit: false,
    },
    'attributeOptionSecondaryDescription': {
      dataSetName: itemDataSetName,
      dbField: 'attribute_option_secondary_description',
      isEdit: false,
    },
    'documentAttribute': { parse: parseItemDocumentAttribute },
    'manufacturingOrderTemplateId': {
      dataSetName: itemDataSetName,
      dbField: 'manufacturing_order_template_id',
      isEdit: false,
    },
    'manufacturingOrderTemplateName': {
      dataSetName: itemDataSetName,
      dbField: 'manufacturing_order_template_name',
      isEdit: false,
    },
    'attributeDisplay': { parse: parseItemAttributeDisplay },
    'templateType': { dataSetName: itemDataSetName, dbField: 'template_type', isEdit: false },
    'templateSalesPriceComputeMode': {
      dataSetName: itemDataSetName,
      dbField: 'sales_price_compute_mode',
      isEdit: false,
    },
    'reportingTagOptionIds': {
      dataSetName: itemDataSetName,
      updateDbField: 'reportingTagOptionIds',
      type: 'array',
      isEdit: true,
      parse: (soItem) => {
        return soItem.reportingTags?.map((reportingTag) => reportingTag.reporting_tag_option_id) ?? []
      },
    },
    'shippedBypassed': { dataSetName: itemDataSetName, dbField: 'shipped_bypassed', isEdit: false },
    'shipToAddress': { dataSetName: itemDataSetName, dbField: 'ship_to_address', isEdit: false },
    'shipToStreet2': { dataSetName: itemDataSetName, dbField: 'ship_to_street2', isEdit: false },
    'shipToZip': { dataSetName: itemDataSetName, dbField: 'ship_to_zip', isEdit: false },
    'fulfillmentStatus': {
      dataSetName: itemDataSetName,
      dbField: 'fulfillment_status',
      isEdit: false,
      type: 'status',
      parse: parseFulfillmentStatus('sales-order-items'),
    },
    'salespersonId': { dataSetName, dbField: 'salesperson_id', type: 'id', relationEntity: 'contacts' },
    'salespersonName': { dataSetName, dbField: 'salesperson_name' },
    'salespersonEmail': { dataSetName, dbField: 'salesperson_email' },
    'salespersonPhone': { dataSetName, dbField: 'salesperson_phone' },
  }

  let fieldsToReturn = Object.keys(fields)
  let _toSkip = []
  if (editOnly) {
    const _toInclude = [
      'id', 'discounted', 'clientZohoTaxId',
      'isTaxed', 'taxId', 'name', 'unitPrice',
      'quantity', 'discount', 'notes', 'contractNumber',
      'promisedDate', 'rank', 'templateId', 'manufacturingOrderId',
      'reportingTags', 'customServiceConfiguration',
      'attributeOptionId', 'customServiceTemplateId', 'accountId',
    ]
    fieldsToReturn = Object.keys(fields).filter((key) => _toInclude.includes(key))
  } else if (skipDuplicate) {
    _toSkip = [
      'promisedDate', 'shipments', 'shipped', 'reserved', 'invoiced', 'resolvedReserved', 'toReserve',
      'createdDate', 'createdBy', 'createdById', 'modifiedDate', 'modifiedBy', 'modifiedById', 'name', 'notes',
      'schedule', 'schedule_id', 'consignmentItemCount', 'consignmentMode',
    ]
  }

  return fieldsToReturn.reduce((acc, key) => {
    const newAcc = { ...acc }
    newAcc[key] = fields[key]
    if (_toSkip.includes(key)) {
      newAcc[key].skip = true
    }
    return newAcc
  }, {})
}

type ParseSalesOrderLineItemOptions = {
  defaultUnits?: MapData['defaultUnits'],
  isPrimaryLanguage?: MapData['isPrimaryLanguage'],
  isDocumentPrimaryLanguage?: MapData['isDocumentPrimaryLanguage'],
  taxDict?: MapData['taxDict'],
  priceMaxDigits?: MapData['priceMaxDigits'],
  measureMaxDigits?: MapData['measureMaxDigits'],
  prefixed?: MapData['apiPrefix']
  [key: string]: any
}

export function parseSalesOrderLineItem(
  item: SalesOrderItemApi,
  {
    defaultUnits,
    isPrimaryLanguage,
    isDocumentPrimaryLanguage,
    taxDict,
    priceMaxDigits,
    measureMaxDigits,
    ...otherOptions
  }: ParseSalesOrderLineItemOptions,
  overriddenFields?: GetFields,
): SalesOrderItem {
  const options = {
    ...otherOptions,
    fields: overriddenFields ?? lineItemFields,
    dataSetName: itemDataSetName,
    defaultUnits,
    isPrimaryLanguage,
    isDocumentPrimaryLanguage,
    taxDict,
    priceMaxDigits,
    measureMaxDigits,
  }
  return parse(item, options)
}

type GetGroupFields = GetFields<SalesOrderGroupApi, SalesOrderGroup>
export function getGroupFields(editOnly?: boolean): GetGroupFields {
  const editFields: GetGroupFields = {
    id: { dataSetName: groupDataSetName, dbField: 'id', isEdit: false, type: 'id' },
    name: { dataSetName: groupDataSetName, dbField: 'name', isEdit: true },
    rank: { dataSetName: groupDataSetName, dbField: 'sales_ranking', isEdit: true, type: 'integer' },
  }
  if (editOnly) {
    return editFields
  }

  const fields: GetGroupFields = {
    id: { dataSetName: groupDataSetName, dbField: 'id' },
    documentName: { dataSetName: groupDataSetName, dbField: 'name' },
    projectId: { dataSetName: groupDataSetName, dbField: 'project_id' },
    isGroup: { parse: () => true },
  }
  return { ...fields, ...editFields }
}

export function parseSalesOrderGroup(group: SalesOrderGroupApi): SalesOrderGroup {
  const options = {
    fields: groupFields,
    dataSetName: groupDataSetName,
  }
  return parse(group, options)
}

export const parseSalesOrderItemNetWeight = (
  qty: number,
  item: SalesOrderItem,
  options?: { measureDigits?: Digit, culture?: string },
) => {
  let weight = +qty || 0

  if (item.dimension != 'weight') {
    weight *= (+item.unitWeight || 0)
  }

  return `${parseNumber(
    convertFromBase('weight', weight, item.weightUnit, true),
    options?.measureDigits,
    options?.culture,
  )} ${item.weightUnit}`
}

export async function _fetchSalesOrder(salesOrderId, mapData) {
  let parsedSalesOrder = getDefaultSalesOrder()

  try {
    const result = await (await safeFetch(buildGetUrl(
      `/new_api/sales-orders/${salesOrderId}`,
      { calculateStatuses: true, isPriceDetailFetch: true, includeTaxDetails: true },
    ))).json()
    if (!result.isSuccess) {
      return
    }

    const [salesOrder] = result.result
    parsedSalesOrder = parseSalesOrder(salesOrder, mapData)

    const customerId = parsedSalesOrder.customerId ? [parsedSalesOrder.customerId] : []
    parsedSalesOrder.customerDetails = (await fetchContactDetails(customerId))[0]
  } catch (err) {
    console.error(err)
  }

  return parsedSalesOrder
}
