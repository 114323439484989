import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'unitedNationsNumberView'

const initialState = {
  dataSetName,
  fields: getFields(),
  unitedNationsNumbersCount: 0,
  unitedNationsNumbers: [],
}

const unitedNationsNumbersSlice = createSlice({
  name: 'unitedNationsNumbers',
  initialState,
  reducers: {
    setUnitedNationsNumbers: (state, action) => {
      state.unitedNationsNumbers = action.payload.data
    },
    setUnitedNationsNumbersCount: (state, action) => {
      state.unitedNationsNumbersCount = action.payload.count
    },
  },
})

export const { setUnitedNationsNumbers, setUnitedNationsNumbersCount } = unitedNationsNumbersSlice.actions

export type MapData = {
  primaryLanguage?: string
  secondaryLanguage?: string
}

export type UnitedNationsNumberApi = Modify<{
  un_number: string
  primary_proper_shipping_name: string
  secondary_proper_shipping_name: string
  primary_hazard_class: string
  secondary_hazard_class: string
  display_name: string
  packing_group: string
}, BaseEntityApi>

export type UnitedNationsNumber = ApiToSlice<UnitedNationsNumberApi>

type UnitedNationsNumberGetFields = GetFields<UnitedNationsNumberApi, UnitedNationsNumber, MapData>

export function getFields(editOnly = false): UnitedNationsNumberGetFields {
  const editFields: UnitedNationsNumberGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'displayName': {
      dataSetName,
      dbField: 'display_name',
      type: 'string',
      isEdit: false,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.unNumber.label',
        { language: options?.primaryLanguage },
      ),
    },
    'unNumber': {
      dataSetName,
      dbField: 'un_number',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.unNumber.label',
      ),
    },
    'primaryProperShippingName': {
      dataSetName,
      dbField: 'primary_proper_shipping_name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.primaryProperShippingName.label',
        { language: options?.primaryLanguage },
      ),
    },
    'secondaryProperShippingName': {
      dataSetName,
      dbField: 'secondary_proper_shipping_name',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.secondaryProperShippingName.label',
        { language: options?.secondaryLanguage },
      ),
    },
    'primaryHazardClass': {
      dataSetName,
      dbField: 'primary_hazard_class',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.primaryHazardClass.label',
        { language: options?.primaryLanguage },
      ),
    },
    'secondaryHazardClass': {
      dataSetName,
      dbField: 'secondary_hazard_class',
      type: 'string',
      isEdit: true,
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => t(
        'unitedNationsNumbers:unitedNationsNumber.fields.secondaryHazardClass.label',
        { language: options?.secondaryLanguage },
      ),
    },
    'packingGroup': {
      dataSetName,
      dbField: 'packing_group',
      type: 'string',
      isEdit: true,
    },
  }

  if (editOnly) return editFields

  const fields: UnitedNationsNumberGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getUnitedNationsNumberTitle(unitedNationsNumber: UnitedNationsNumber): string {
  return unitedNationsNumber.displayName
}

export async function fetchUnitedNationsNumbersByIds(ids: string[], mapData?: MapData, data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<UnitedNationsNumberApi>(
    buildGetUrl(`/new_api/united-nations-numbers/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((unitedNationsNumber) => parseUnitedNationsNumber(unitedNationsNumber, mapData)) :
    []
}

export function fetchUnitedNationsNumbers(mapData: MapData, fetchData?: Record<string, any>) {
  return async function fetchUnitedNationsNumbersThunk(dispatch: AppDispatch) {
    const data = await _fetchUnitedNationsNumbers(fetchData, mapData)
    dispatch(setUnitedNationsNumbers({ data }))
    return data
  }
}

export async function _fetchUnitedNationsNumbers(fetchData: Record<string, any>, mapData: MapData) {
  let unitedNationsNumbers = []

  try {
    const { isSuccess, result } = await safeFetchJson<UnitedNationsNumberApi>(
      buildGetUrl('/new_api/united-nations-numbers', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      unitedNationsNumbers = result.map((unitedNationsNumber) => parseUnitedNationsNumber(unitedNationsNumber, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return unitedNationsNumbers
}

export function fetchUnitedNationsNumbersCount(fetchData?: Record<string, any>) {
  return async function fetchUnitedNationsNumbersCountThunk(dispatch: AppDispatch) {
    const count = await _fetchUnitedNationsNumbersCount(fetchData)
    dispatch(setUnitedNationsNumbersCount({ count }))
    return count
  }
}

export async function _fetchUnitedNationsNumbersCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/united-nations-numbers/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function createUnitedNationsNumber(createData: Partial<UnitedNationsNumberApi>[], mapData: MapData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: createData }),
  }
  return async function createUnitedNationsNumbersThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<UnitedNationsNumberApi, false>(
        '/new_api/united-nations-numbers', requestOptions,
      )
      const unitedNationsNumber = isSuccess && !isJob<UnitedNationsNumberApi, false>(result) ?
        parseUnitedNationsNumber(result, mapData) :
        null
      const error = !isSuccess ? result : null
      return { isSuccess, unitedNationsNumber, error }
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export function deleteUnitedNationsNumbers(ids: string[], mapData: MapData) {
  if (!ids?.length) return []

  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  const url = `/new_api/united-nations-numbers/${ids}`
  return async function deleteUnitedNationsNumbersThunk(dispatch: AppDispatch) {
    try {
      const { isSuccess, result } = await safeFetchJson<UnitedNationsNumberApi>(url, requestOptions)
      const error = !isSuccess ? result : null
      const _result = { isSuccess, error, unitedNationNumbers: undefined, unitedNationNumber: undefined }
      if (Array.isArray(result)) {
        _result.unitedNationNumbers = []
        if (isSuccess && !isJob(result)) {
          _result.unitedNationNumbers = result.map((unitedNationNumber) => {
            return parseUnitedNationsNumber(unitedNationNumber, mapData)
          })
        }
      } else {
        _result.unitedNationNumber = isSuccess && !isJob<UnitedNationsNumberApi, false>(result) ?
          parseUnitedNationsNumber(result, mapData) :
          null
      }

      return _result
    } catch (error) {
      console.error(error)
      return { isSuccess: false, error }
    }
  }
}

export async function _updateUnitedNationsNumber(
  id: string,
  updateData: Partial<UnitedNationsNumberApi>, mapData: MapData,
) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<UnitedNationsNumberApi, false>(
      `/new_api/united-nations-numbers/${id}`, requestOptions,
    )
    const unitedNationsNumber = isSuccess && !isJob<UnitedNationsNumberApi, false>(result) ?
      parseUnitedNationsNumber(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, unitedNationsNumber, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseUnitedNationsNumber(
  unitedNationsNumber: UnitedNationsNumberApi,
  mapData: MapData,
): UnitedNationsNumber {
  const options = {
    defaultData: getDefaultUnitedNationsNumber(),
    fields: initialState.fields,
    dataSetName: dataSetName,
    ...mapData,
  }

  return parse(unitedNationsNumber, options)
}

function getDefaultUnitedNationsNumber(): UnitedNationsNumber {
  return parse({}, { fields: initialState.fields })
}

export default unitedNationsNumbersSlice.reducer
