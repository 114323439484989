import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'taxByRegionView'

const initialState = {
  dataSetName,
  fields: getFields(),
  taxesByRegionCount: 0,
  taxesByRegion: [],
}

const taxesByRegionSlice = createSlice({
  name: 'taxesByRegion',
  initialState,
  reducers: {
    setTaxesByRegion: (state, action) => {
      state.taxesByRegion = action.payload.data
    },
    setTaxesByRegionCount: (state, action) => {
      state.taxesByRegionCount = action.payload.count
    },
  },
})

export const { setTaxesByRegion, setTaxesByRegionCount } = taxesByRegionSlice.actions

export type TaxByRegionApi = Modify<{
  state_id: string
  country_id: string
  is_default: boolean
  tax_id: string
  tax_name: string
  display_title: string
  secondary_display_title: string
}, BaseEntityApi>

export type TaxByRegion = ApiToSlice<TaxByRegionApi>

export type MapData = {
  primaryLanguage?: string
  secondaryLanguage?: string
  isPrimaryLanguage?: boolean
}

type TaxByRegionGetFields = GetFields<TaxByRegionApi, TaxByRegion>

export function getFields(editOnly = false): TaxByRegionGetFields {
  const editFields: TaxByRegionGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'taxId': { dataSetName, dbField: 'tax_id', type: 'id', relationEntity: 'taxes', isEdit: true },
  }

  if (editOnly) return editFields

  const fields: TaxByRegionGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'isDefault': { dataSetName, dbField: 'is_default', type: 'boolean' },
    'taxName': { dataSetName, dbField: 'tax_name', type: 'string' },
    'displayTitle': {
      dataSetName,
      dbField: 'display_title',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('taxesByRegion:taxByRegion.fields.title.label', { language: options.primaryLanguage })
      },
    },
    'secondaryDisplayTitle': {
      dataSetName,
      dbField: 'secondary_display_title',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('taxesByRegion:taxByRegion.fields.title.label', { language: options.secondaryLanguage })
      },
    },
    'stateId': { dataSetName, dbField: 'state_id', type: 'id', relationEntity: 'states' },
    'countryId': { dataSetName, dbField: 'country_id', type: 'id', relationEntity: 'countries' },
  }

  return { ...fields, ...editFields }
}

export function getTaxByRegionTitle(taxByRegion: TaxByRegion): string {
  return taxByRegion.displayTitle
}

export async function fetchTaxesByRegionByIds(ids: string[], data?: Record<string, any>, mapData?: MapData) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<TaxByRegionApi>(
    buildGetUrl(`/new_api/accounting/taxes-by-region/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((taxByRegion) => parseTaxByRegion(taxByRegion, mapData)) :
    []
}

export function fetchTaxesByRegion(fetchData?: Record<string, any>, mapData?: MapData) {
  return async function fetchTaxesByRegionThunk(dispatch: AppDispatch) {
    const data = await _fetchTaxesByRegion(fetchData, mapData)
    dispatch(setTaxesByRegion({ data }))
    return data
  }
}

export async function _fetchTaxesByRegion(fetchData: Record<string, any>, mapData?: MapData) {
  let taxesByRegion = []

  try {
    const { isSuccess, result } = await safeFetchJson<TaxByRegionApi>(
      buildGetUrl('/new_api/accounting/taxes-by-region', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      taxesByRegion = result.map((taxByRegion) => parseTaxByRegion(taxByRegion, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return taxesByRegion
}

export function fetchTaxesByRegionCount(fetchData?: Record<string, any>) {
  return async function fetchTaxesByRegionCountThunk(dispatch: AppDispatch) {
    const count = await _fetchTaxesByRegionCount(fetchData)
    dispatch(setTaxesByRegionCount({ count }))
    return count
  }
}

export async function _fetchTaxesByRegionCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/accounting/taxes-by-region/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _updateTaxByRegion(id: string, updateData: Partial<TaxByRegionApi>, mapData?: MapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ taxByRegion: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<TaxByRegionApi, false>(
      `/new_api/accounting/taxes-by-region/${id}`, requestOptions,
    )
    const taxByRegion = isSuccess && !isJob<TaxByRegionApi, false>(result) ?
      parseTaxByRegion(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, taxByRegion, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseTaxByRegion(taxByRegion: TaxByRegionApi, mapData?: MapData): TaxByRegion {
  const options = {
    ...mapData,
    defaultData: getDefaultTaxByRegion(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(taxByRegion, options)
}

function getDefaultTaxByRegion(): TaxByRegion {
  return parse({}, { fields: initialState.fields })
}

export default taxesByRegionSlice.reducer
