// ! TODO (bzoretic): finish typing this file, too many Record<string, any>
import moment from 'moment'
import { AppDispatch } from 'store'
import { RefreshReportData } from 'types/report'
import { GetFields } from 'types/slices'

import { prefixItem } from 'components/alix-front/smart-grid/columns/utils'

import { buildGetUrl, parse } from 'utils/api'
import { dateToTimezoneString } from 'utils/dateParser'
import { getFirstString } from 'utils/getFirstOfType'
import { buildDictionary, dataToDbData } from 'utils/mapperHelper'
import { isJob, parseError, safeFetch, safeFetchJson } from 'utils/safeFetch'
import { convertFromDollarPerBase } from 'utils/unitConverter'

import { parseContact } from 'reducers/contacts/contactsSlice'
import {
  parseSalesOrderLineItem,
  getLineItemFields as getSalesOrderLineItemFields,
  _fetchSalesOrder,
} from 'reducers/sales-orders/shared'
import { UPDATE_SALES_ORDER } from 'reducers/sales-orders/types/actions'

import {
  ProForma,
  ProFormaApi,
  ProFormaItem,
  ProFormaItemApi,
  Schedule,
  ScheduleApi,
  ScheduleSalesOrderItemRel,
  ScheduleSalesOrderItemRelApi,
  Shipment,
  ShipmentApi,
  ShipmentJoinedLineItem,
  ShipmentJoinedLineItemApi,
  ShipmentLineItem,
  ShipmentLineItemApi,
} from './types'
import {
  GET_SHIPMENTS,
  GET_SHIPMENTS_COUNT,
  CLEAR_SHIPMENTS,
  GET_SHIPMENT_ITEMS_COUNT,
  GET_SHIPMENT_ITEMS,
  CLEAR_SHIPMENT_ITEMS,
  DELETE_SHIPMENT,
  CREATE_SHIPMENT,
  SET_SCHEDULE_LISTENER,
  CLEAR_SCHEDULE_LISTENER,
  UPDATE_SHIPMENT,
} from './types/actions'

export const dataSetName = 'shipmentView'
export const shipmentItemDataSetName = 'shipmentLineItem'

const salesOrderItemDataSetName = 'salesOrderItem'
const scheduleDataSetName = 'shipmentSchedule'
const scheduleSalesOrderItemRelDataSetName = 'shipmentScheduleSalesOrderItemRelView'
const proFormaDataSetName = 'proFormaView'
const proFormaItemsDataSetName = 'proFormaItemView'

const initialState = {
  dataSetName,
  fields: getFields(),
  shipmentsCount: 0,
  shipments: [],
  itemFields: getLineItemFields(),
  joinedItemFields: getJoinedLineItemFields(),
  shipmentItemsCount: 0,
  shipmentItems: [],
  scheduleFields: getScheduleFields(),
  scheduleSalesOrderItemRelFields: getScheduleSalesOrderItemRelFields(),
  scheduleListeners: {},
  proFormaFields: getProFormaFields(),
  proFormaItemsFields: getProFormaItemsFields(),
}

export default function shipmentsReducer(state = initialState, action: any) {
  const { payload } = action
  switch (action.type) {
  case GET_SHIPMENTS_COUNT: {
    return {
      ...state,
      shipmentsCount: payload,
    }
  }
  case GET_SHIPMENTS: {
    return {
      ...state,
      shipments: payload,
    }
  }
  case CLEAR_SHIPMENTS: {
    return {
      ...state,
      shipmentsCount: 0,
      shipments: [],
    }
  }
  case GET_SHIPMENT_ITEMS_COUNT: {
    return {
      ...state,
      shipmentItemsCount: payload,
    }
  }
  case GET_SHIPMENT_ITEMS: {
    return {
      ...state,
      shipmentItems: payload,
    }
  }
  case CLEAR_SHIPMENT_ITEMS: {
    return {
      ...state,
      shipmentItemsCount: 0,
      shipmentItems: [],
    }
  }
  case SET_SCHEDULE_LISTENER: {
    return { ...state, scheduleListeners: { ...state.scheduleListeners, [payload.jobUUID]: payload.listener } }
  }
  case CLEAR_SCHEDULE_LISTENER: {
    const scheduleListeners: any = { ...state.scheduleListeners }
    delete scheduleListeners[payload.jobUUID]
    return { ...state, scheduleListeners }
  }
  default: {
    return state
  }
  }
}

type MapData = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  }
  isOverwrittenNotesFirst?: boolean
  isOverwrittenNameFirst?: boolean
  isDescriptionByConfig?: boolean
  language?: string
  companyInfo?: Record<string, any>
  itemTitleFieldConfig?: string
}

export function getFields(): GetFields<ShipmentApi, Shipment> {
  return {
    'clientIsShipper': { dataSetName, dbField: 'client_is_shipper', type: 'boolean' },
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'isGrayLabel': { dataSetName, dbField: 'is_gray_label', type: 'boolean' },
    'showGrossInBol': { dataSetName, dbField: 'show_gross_in_bol', type: 'boolean' },
    'showLongDescriptionInBol': { dataSetName, dbField: 'show_long_description_in_bol', type: 'boolean' },
    'showNetInBol': { dataSetName, dbField: 'show_net_in_bol', type: 'boolean' },
    'showPickedItemsInBol': { dataSetName, dbField: 'show_picked_items_in_bol', type: 'boolean' },
    'showProjectNumberInBol': { dataSetName, dbField: 'show_project_number_in_bol', type: 'boolean' },
    'showListNumberInBol': { dataSetName, dbField: 'show_list_number_in_bol', type: 'boolean' },
    'showProjectOwnerInBol': { dataSetName, dbField: 'show_project_owner_in_bol', type: 'boolean' },
    'showSignaturesInBol': { dataSetName, dbField: 'show_signatures_in_bol', type: 'boolean' },
    'showTareInBol': { dataSetName, dbField: 'show_tare_in_bol', type: 'boolean' },
    'showTotalInBol': { dataSetName, dbField: 'show_total_in_bol', type: 'boolean' },

    'plantId': { dataSetName, dbField: 'plant_id', type: 'id', isEdit: true },
    'plantName': { dataSetName, dbField: 'plant_name', type: 'string' },

    'cost': { dataSetName, dbField: 'cost', type: 'currency', isEdit: true },

    'addressId': { dataSetName, dbField: 'address_id', type: 'id', relationEntity: 'addresses', isEdit: true },
    'carrierId': { dataSetName, dbField: 'carrier_id', type: 'id', relationEntity: 'contacts', isEdit: true },
    'contactCarrierAccountId': {
      dataSetName,
      dbField: 'contact_carrier_account_id',
      type: 'id',
      relationEntity: 'contact-carrier-accounts',
      formDefaultValue: null,
      customFieldTranslationKey: (t) => t('contacts:contactCarrierAccount.fields.contactCarrierAccount.label'),
      isEdit: true,
    },
    'checklistId': { dataSetName, dbField: 'checklist_id', type: 'id', relationEntity: 'checklists' },
    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'clientDetailId': { dataSetName, dbField: 'client_detail_id', type: 'id' },
    'clientId': { dataSetName, dbField: 'customer_id', type: 'id', relationEntity: 'contacts', isEdit: true },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'nextStepId': { dataSetName, dbField: 'next_step_id', type: 'id' },
    'shipperAddressId': {
      dataSetName,
      dbField: 'shipper_address_id',
      type: 'id',
      relationEntity: 'addresses',
    },
    // ! TODO (bzoretic): when steps exist as an entity in entities.js, add property relationEntity: 'steps' here.
    'stepId': { dataSetName, dbField: 'step_id', type: 'id', isEdit: true },

    'number': { dataSetName, dbField: 'number', type: 'integer' },
    'status': {
      dataSetName,
      dbField: 'status',
      type: 'integer',
      customEventValueTranslationKey: (value) => `shipments:status.${value ?? 1}`,
    },
    'year': { dataSetName, dbField: 'year', type: 'integer' },

    'salesOrderNumberAggregate': { dataSetName, dbField: 'sales_order_number_aggregate' },

    'bolOrderKey1': { dataSetName, dbField: 'bol_order_key1', type: 'string' },
    'bolOrderKey2': { dataSetName, dbField: 'bol_order_key2', type: 'string' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'displayUnit': { dataSetName, dbField: 'display_unit', type: 'string' },
    'formattedNumber': { dataSetName, dbField: 'formated_number', type: 'string' },
    'instructions': { dataSetName, dbField: 'instructions', type: 'text' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'name': { dataSetName, dbField: 'name', type: 'string' },
    'poNumber': { dataSetName, dbField: 'po_number', type: 'string', isEdit: true },
    'totalDisplayUnit': { dataSetName, dbField: 'total_display_unit', type: 'string' },
    'trackingNumber': { dataSetName, dbField: 'tracking_number', type: 'string' },
    'trailer': { dataSetName, dbField: 'trailer', type: 'string' },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'expeditedDate': { dataSetName, dbField: 'expedited_date', type: 'date', isTimezoned: false },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
    'plannedDate': { dataSetName, dbField: 'planned_date', type: 'date', isEdit: true, isTimezoned: false },
    'plannedDatetime': { dataSetName, dbField: 'planned_datetime', type: 'timestamp', isEdit: true },

    'address': { dataSetName, dbField: 'address_address', type: 'string' },
    'city': { dataSetName, dbField: 'address_city', type: 'string' },
    'province': { dataSetName, dbField: 'address_state', type: 'string' },
    'postalCode': { dataSetName, dbField: 'address_zip', type: 'string' },
    'country': { dataSetName, dbField: 'address_country', type: 'string' },
    'customer': { dataSetName, dbField: 'customer_display_name', type: 'string' },
    'carrier': { dataSetName, dbField: 'carrier_display_name', type: 'string' },
    'proFormaLanguage': { dataSetName, dbField: 'pro_forma_language', type: 'string' },
    'lists': { parse: (shipment) => shipment.lists },
    'lineItems': { dataSetName, dbField: 'lineItems', isEdit: true, type: 'array' },
  }
}

export function getProFormaFields(): GetFields<ProFormaApi, ProForma> {
  return {
    'shipmentId': { dataSetName: proFormaDataSetName, dbField: 'shipment_id', type: 'id' },
    'soId': { dataSetName: proFormaDataSetName, dbField: 'sales_order_id', type: 'id' },
    'customerId': { dataSetName: proFormaDataSetName, dbField: 'customer_id', type: 'id' },
    'shipmentNumber': { dataSetName: proFormaDataSetName, dbField: 'shipment_number', type: 'string' },
    'shipmentDate': { dataSetName: proFormaDataSetName, dbField: 'shipment_date', type: 'date', isTimezoned: false },
    'dueDate': { dataSetName: proFormaDataSetName, dbField: 'due_date', type: 'date', isTimezoned: false },
    'paymentTerms': { dataSetName: proFormaDataSetName, dbField: 'payment_terms', type: 'string' },
    'shipmentTrackingNumber': { dataSetName: proFormaDataSetName, dbField: 'shipment_tracking_number', type: 'string' },
    'shipmentReference': { dataSetName: proFormaDataSetName, dbField: 'shipment_reference', type: 'string' },
    'carrierDisplayName': { dataSetName: proFormaDataSetName, dbField: 'carrier_display_name', type: 'string' },
    'number': { dataSetName: proFormaDataSetName, dbField: 'pro_forma_number', type: 'string' },
    'contactPersonId': { dataSetName: proFormaDataSetName, dbField: 'contact_person_id', type: 'id' },
    'customer': { parse: (props: Record<string, any>) => parseContact(props.customer) },
    'lineItems': { parse: parseLineItems },
    'language': { dataSetName: proFormaDataSetName, dbField: 'language', type: 'string' },
  }
}

export function getProFormaItemsFields(): GetFields<ProFormaItemApi, ProFormaItem> {
  return {
    'id': { dataSetName: proFormaItemsDataSetName, dbField: 'id', type: 'id' },
    'shipmentId': { dataSetName: proFormaItemsDataSetName, dbField: 'shipment_id', type: 'id' },
    'discount': { dataSetName: proFormaItemsDataSetName, dbField: 'discount', type: 'float' },
    'qtyPicked': { dataSetName: proFormaItemsDataSetName, dbField: 'quantity_picked', type: 'float' },
    'totalQty': { dataSetName: proFormaItemsDataSetName, dbField: 'total_quantity', type: 'float' },
    'unitPrice': { dataSetName: proFormaItemsDataSetName, dbField: 'unit_price', type: 'float' },
    'primaryName': { dataSetName: proFormaItemsDataSetName, dbField: 'primary_name', type: 'string' },
    'secondaryName': { dataSetName: proFormaItemsDataSetName, dbField: 'secondary_name', type: 'string' },
    'overwrittenNotes': { dataSetName: proFormaItemsDataSetName, dbField: 'overwritten_notes', type: 'string' },
    'primaryLongDescription': {
      dataSetName: proFormaItemsDataSetName,
      dbField: 'primary_long_description',
      type: 'string',
    },
    'secondaryLongDescription': {
      dataSetName: proFormaItemsDataSetName,
      dbField: 'secondary_long_description',
      type: 'string',
    },
    'dimension': { dataSetName: proFormaItemsDataSetName, dbField: 'dimension', type: 'string' },
    'unit': { dataSetName: proFormaItemsDataSetName, dbField: 'unit', type: 'string' },
    'overwrittenName': { dataSetName: proFormaItemsDataSetName, dbField: 'overwritten_name', type: 'string' },
    'countryOfOrigin': {
      dataSetName: proFormaItemsDataSetName,
      dbField: 'country_of_origin_primary_name',
      type: 'string',
    },
    'countryOfOriginSecondary': {
      dataSetName: proFormaItemsDataSetName,
      dbField: 'country_of_origin_secondary_name',
      type: 'string',
    },
    'harmonizedSystemCode': {
      dataSetName: proFormaItemsDataSetName,
      dbField: 'harmonized_system_code_code',
      type: 'string',
    },
    'rate': { parse: parseRate },
    'amount': { parse: parseAmount },
  }
}

export type OptionsType = {
  defaultUnits?: {
    qty: string
    weight: string
    length: string
    surface: string
    volume: string
  },
  isPrimaryLanguage?: boolean,
  isDocumentPrimaryLanguage?: boolean,
  taxDict?: Record<string, any>,
  priceMaxDigits?: number,
  measureMaxDigits?: number,
}

function getUnit(shipmentItem: any, options: OptionsType = {}) {
  const defaultUnits = options.defaultUnits || {}
  return shipmentItem.measure_unit || defaultUnits[shipmentItem.salesorderitem_dimension_to_display]
}

function getDefaultShipmentItem() {
  return parse({}, { fields: initialState.fields })
}

export function parseShipmentItem(
  item: ShipmentLineItemApi,
  mapData: MapData,
  onlyParseAvailableFields = false,
): ShipmentLineItem {
  const options = {
    defaultData: getDefaultShipmentItem(),
    fields: initialState.itemFields,
    dataSetName: shipmentItemDataSetName,
    defaultUnits: mapData?.defaultUnits,
    onlyParseAvailableFields,
  }

  return parse(item, options)
}

export const _prefixShipmentLineItem = '$lineitem$'
export const prefixShipmentLineItem = (item: Record<string, any>) => prefixItem(item, _prefixShipmentLineItem)

export function getFieldsJoinedToShipment() {
  const shipmentFields = getFields()

  const merged = {
    ...shipmentFields,
    ...prefixShipmentLineItem(initialState.joinedItemFields),
    id: initialState.joinedItemFields.id,
    shipmentId: shipmentFields.id,
  }

  return merged
}

export function parseJoinedShipmentItem(item: any, mapData: MapData) {
  const parsedShipment = parseShipment(item)

  const options = {
    ...mapData,
    fields: initialState.joinedItemFields,
    dataSetName,
  }

  const parsedItem = _parseJoinedShipmentItem({ ...item, id: item.shipmentlineitem_id }, options)

  const merged = {
    ...parsedShipment,
    ...prefixShipmentLineItem(parsedItem),
    id: parsedItem.id,
    shipmentId: parsedShipment.id,
  }

  return merged
}

export function getJoinedLineItemFields(): GetFields<
  ShipmentJoinedLineItemApi,
  ShipmentJoinedLineItem,
  null,
  'manufacturing_order' | 'salesorder' | 'templateview'> {
  return {
    id: { dataSetName: shipmentItemDataSetName, dbField: 'id', type: 'id' },
    primaryName: { dataSetName: salesOrderItemDataSetName, dbField: 'primary_name' },
    secondaryName: { dataSetName: salesOrderItemDataSetName, dbField: 'secondary_name' },
    manufacturingOrderTitle: { dataSetName: 'item', dataSetAlias: 'manufacturing_order', dbField: 'title' },
    manufacturingOrderType: { dataSetName: 'item', dataSetAlias: 'manufacturing_order', dbField: 'type' },
    manufacturingOrderSku: { dataSetName: 'item', dataSetAlias: 'manufacturing_order', dbField: 'sku' },
    manufacturingOrderPartNumber: { dataSetName: 'item', dataSetAlias: 'manufacturing_order', dbField: 'part_number' },
    manufacturingOrderFormattedNumber: {
      dataSetName: 'item',
      dataSetAlias: 'manufacturing_order',
      dbField: 'formated_number',
    },
    salesOrderName: { dataSetName: 'salesOrder', dbField: 'name' },
    templateSku: { dataSetName: 'templateView', dbField: 'sku' },
    templatePartNumber: { dataSetName: 'templateView', dbField: 'part_number' },

    grossWeightUnit: {
      parse: (
        props: any,
        options: OptionsType,
      ) => _parseWeightUnit(props.manufacturing_order_weight_gross_unit, options),
    },
    netWeightUnit: {
      parse: (
        props: any,
        options: OptionsType,
      ) => _parseWeightUnit(props.manufacturing_order_weight_net_unit, options),
    },
    tareWeightUnit: {
      parse: (
        props: any,
        options: OptionsType,
      ) => _parseWeightUnit(props.manufacturing_order_weight_tare_unit, options),
    },

    displayDescription: {
      parse: (item, mapData: MapData = {}) => {
        const {
          isOverwrittenNameFirst = false,
          isDescriptionByConfig = false,
          language, companyInfo, itemTitleFieldConfig,
        } = mapData

        if (isOverwrittenNameFirst && item.salesorderitem_overwritten_name) {
          return item.salesorderitem_overwritten_name
        }

        const primary = item.salesorderitem_primary_name
        const secondary = item.salesorderitem_secondary_name

        let description = primary || secondary || ''

        if (language) {
          const isPrimaryLanguage = language != `${companyInfo?.secondaryLanguage}-${companyInfo?.countryCode}`
          description = (isPrimaryLanguage ? primary : secondary) || primary || secondary || ''
        }

        if (
          isDescriptionByConfig &&
          itemTitleFieldConfig != 'title' &&
          item.manufacturing_order_id
        ) {
          description = item[`manufacturing_order_${itemTitleFieldConfig}`] ||
            description
        }

        description = description || item.salesorderitem_overwritten_name

        return description
      },
    },
    displayDescriptionSub: {
      parse: (item) => {
        let prefix
        let suffix
        if (item.shipmentlineitem_is_handled_by_ledger) {
          prefix = item.salesorder_name
          suffix = item.templateview_sku || item.templateview_part_number
        } else {
          prefix = item.manufacturing_order_formated_number
          suffix = item.manufacturing_order_sku || item.manufacturing_order_part_number
        }

        return `${prefix}${suffix ? `/${suffix}` : ''}`
      },
    },
    displayLongDescription: {
      parse: (item, mapData: MapData = {}) => {
        const { language, isOverwrittenNotesFirst = true, companyInfo } = mapData

        if (isOverwrittenNotesFirst && item.salesorderitem_overwritten_notes) {
          return item.salesorderitem_overwritten_notes
        }

        const primary = item.salesorderitem_primary_notes
        const secondary = item.salesorderitem_secondary_notes
        let description = primary || secondary || ''
        if (language) {
          const isPrimaryLanguage = language != `${companyInfo?.secondaryLanguage}-${companyInfo?.countryCode}`
          description = (isPrimaryLanguage ? primary : secondary) || primary || secondary || ''
        }

        return description || item.salesorderitem_overwritten_notes
      },
    },
    displayNumber: { parseWithParsedData: _parseDisplayNumber },
    unit: { parse: _parseUnit },
    dimension: { dataSetName: 'salesOrderItem', dbField: 'dimension_to_display' },
    ordered: { dataSetName: 'salesOrderItem', dbField: 'measure' },

    toPrepare: { dataSetName: shipmentItemDataSetName, dbField: 'planned_measure' },
    toPrepareMax: {
      parse: (item) => +item.shipmentlineitem_planned_measure * +item.templateview_tolerance_over_factor,
    },
    toPrepareMin: {
      parse: (item) => +item.shipmentlineitem_planned_measure * +item.templateview_tolerance_under_factor,
    },

    plannedMeasureCalculated: {
      parse: (item) => {
        return item.salesorderitem_dimension_to_display == 'qty' ? +item.shipmentlineitem_planned_measure || 1 : 1
      },
    },
    picked: { dataSetName: shipmentItemDataSetName, dbField: 'current_measure' },
    remainingPlannedLedgerMeasure: {
      parse: (item) => {
        const reserved = (+item.active_planned_ledger_planned_measure_sum || 0) +
        (+item.resolved_planned_ledger_planned_measure_sum || 0)

        return Math.max((+item.shipmentlineitem_planned_measure || 0) *
          item.templateview_tolerance_over_factor - reserved, 0)
      },
    },
    salesOrderItemMeasure: {
      parse: (item) => item.salesorderitem_measure * item.templateview_tolerance_over_factor,
    },

    toleranceOverFactor: {
      dataSetName: 'templateView',
      dbField: 'tolerance_over',
      parse: (item) => item.templateview_tolerance_over ?? 0,
    },
    toleranceUnderFactor: {
      dataSetName: 'templateView',
      dbField: 'tolerance_under',
      parse: (item) => item.templateview_tolerance_under ?? 0,
    },
  }
}

function _parseDisplayNumber(item: ShipmentJoinedLineItem, _, unParsedItem: ShipmentJoinedLineItemApi) {
  if (unParsedItem.shipmentlineitem_is_handled_by_ledger) {
    return item.displayDescriptionSub
  } else {
    return unParsedItem.manufacturing_order_formated_number ?? ''
  }
}

function _parseUnit(item: ShipmentJoinedLineItemApi, mapData: MapData) {
  return item.shipmentlineitem_measure_unit || mapData?.defaultUnits?.[item.shipmentlineitem_dimension_to_display]
}

function _parseWeightUnit(weightUnit: string, options: OptionsType) {
  const defaultUnits = options?.defaultUnits || {}
  return weightUnit || defaultUnits['weight']
}

function _parseJoinedShipmentItem(item: ShipmentJoinedLineItemApi, options = {}) {
  const parsedLine = parse(item, options)

  calculateWeight(item, parsedLine)

  return parsedLine
}

function calculateWeight(baseLine: ShipmentJoinedLineItemApi, parsedLine: ShipmentJoinedLineItem) {
  const isHandledByLedger = baseLine.shipmentlineitem_is_handled_by_ledger

  if (isHandledByLedger) {
    const dimension = baseLine.salesorderitem_dimension_to_display
    const plannedLedgerMeasureSum = baseLine.active_planned_ledger_measure_sum ||
      baseLine.resolved_planned_ledger_measure_sum || 0
    const plannedLedgerNetWeightSum = baseLine.active_planned_ledger_net_weight_sum ||
      baseLine.resolved_planned_ledger_net_weight_sum || 0
    const plannedLedgerTareWeightSum = baseLine.active_planned_ledger_tare_weight_sum ||
      baseLine.resolved_planned_ledger_tare_weight_sum || 0
    const plannedLedgerPlannedMeasureSum = baseLine.active_planned_ledger_planned_measure_sum ||
      baseLine.resolved_planned_ledger_planned_measure_sum || 0
    const plannedLedgerPlannedNetWeightSum = baseLine.active_planned_ledger_planned_net_weight_sum ||
      baseLine.resolved_planned_ledger_planned_net_weight_sum || 0
    const plannedLedgerPlannedTareWeightSum = baseLine.active_planned_ledger_planned_tare_weight_sum ||
      baseLine.resolved_planned_ledger_planned_tare_weight_sum || 0

    parsedLine.netWeight = dimension === 'weight' ?
      plannedLedgerMeasureSum :
      (
        baseLine.templateview_inventory_management_type === 'not_managed' ?
          plannedLedgerMeasureSum*(+baseLine.templateview_unit_weight || 0) :
          plannedLedgerNetWeightSum
      )

    parsedLine.tareWeight = plannedLedgerTareWeightSum
    parsedLine.grossWeight = parsedLine.netWeight + parsedLine.tareWeight

    parsedLine.plannedNetWeight = dimension === 'weight' ?
      plannedLedgerPlannedMeasureSum :
      (
        baseLine.templateview_inventory_management_type === 'not_managed' ?
          plannedLedgerPlannedMeasureSum*(+baseLine.templateview_unit_weight || 0) :
          plannedLedgerPlannedNetWeightSum
      )

    parsedLine.plannedTareWeight = plannedLedgerPlannedTareWeightSum
    parsedLine.plannedGrossWeight = parsedLine.plannedNetWeight + parsedLine.plannedTareWeight

    parsedLine.theoreticalNetWeight = (dimension === 'weight' ?
      baseLine.shipmentlineitem_planned_measure :
      (+baseLine.templateview_unit_weight || 0) * baseLine.shipmentlineitem_planned_measure) || 0

    parsedLine.theoreticalTareWeight = baseLine.templateview_tare_weight || 0
    parsedLine.theoreticalGrossWeight = parsedLine.theoreticalNetWeight + parsedLine.theoreticalTareWeight
  } else {
    parsedLine.netWeight = baseLine.manufacturing_order_weight_net || 0
    parsedLine.tareWeight = baseLine.manufacturing_order_weight_tare || 0
    parsedLine.grossWeight = parsedLine.netWeight + parsedLine.tareWeight

    parsedLine.plannedNetWeight = parsedLine.netWeight
    parsedLine.plannedTareWeight = parsedLine.tareWeight
    parsedLine.plannedGrossWeight = parsedLine.grossWeight

    parsedLine.theoreticalNetWeight = parsedLine.netWeight
    parsedLine.theoreticalTareWeight = parsedLine.tareWeight
    parsedLine.theoreticalGrossWeight = parsedLine.grossWeight
  }
}

export function getLineItemFields(): GetFields<ShipmentLineItemApi, ShipmentLineItem, null, 'shipment'> {
  return {
    'itemName': {
      parse: (props) => {
        return getFirstString(
          props['salesorderitemview_primary_name'],
          props['template_description'],
        )
      },
    },
    'number': { dataSetName: shipmentItemDataSetName, dbField: 'manufacturing_order_formated_number' },
    'id': { dataSetName: shipmentItemDataSetName, dbField: 'id', type: 'id' },
    'shipmentId': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'shipment_id',
      type: 'id',
      relationEntity: 'shipments',
    },
    'shipment': { dataSetName, dataSetAlias: 'shipment', dbField: 'formated_number' },
    'shipmentStatus': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'shipment_status',
      type: 'status',
      dictionaryKey: 'shipment',
      translationPath: 'shipments:status',
      values: ['1', '2', '3'],
      defaultValue: '1',
      parse: (props) => props.shipment_status ?? '1',
    },
    'modifiedDate': { dataSetName: shipmentItemDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: shipmentItemDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: shipmentItemDataSetName, dbField: 'modified_by_id', type: 'id' },
    'salesOrderItemId': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'sales_order_item_id',
      type: 'id',
      isEdit: true,
      relationEntity: 'sales-order-items',
    },
    'shipmentPoNumber': { dataSetName, dataSetAlias: 'shipment', dbField: 'po_number' },
    'shipmentPlannedDate': { dataSetName, dataSetAlias: 'shipment', dbField: 'planned_date', isTimezoned: false },
    'shipmentPlannedDatetime': {
      dataSetName,
      dataSetAlias: 'shipment',
      dbField: 'planned_datetime',
      type: 'timestamp',
    },
    'unit': { parse: getUnit },
    'picked': { dataSetName: shipmentItemDataSetName, dbField: 'current_measure' },
    'toPrepare': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'planned_measure',
      isEdit: false,
    },
    'toPrepareMax': {
      parse: (item) => +item.planned_measure * +item.template_tolerance_over_factor,
      isEdit: false,
    },
    'toPrepareMin': {
      parse: (item) => +item.planned_measure * +item.template_tolerance_under_factor,
      isEdit: false,
    },
    'currentMeasure': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'current_measure',
      type: 'measure',
    },
    'plannedMeasure': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'planned_measure',
      type: 'measure',
      isEdit: true,
    },
    'dimension': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'dimension_to_display',
      type: 'string',
    },
    'primaryDescription': {
      // ! This is used for printing, value is parsed in alix-front
      parse: (data) => data.display_description,
      type: 'string',
    },
    'partNumber': {
      parse: (data) => data.is_handled_by_ledger ? data.template_part_number : data.manufacturing_order_part_number,
      type: 'string',
    },
    'sku': {
      parse: (data) => data.is_handled_by_ledger ? data.template_sku : null,
      type: 'string',
    },
    'shipmentListId': {
      dataSetName: shipmentItemDataSetName,
      dbField: 'shipment_list_id',
      isEdit: true,
      type: 'id',
    },
  }
}

export function getScheduleFields(): GetFields<ScheduleApi, Schedule> {
  return {
    'id': { dataSetName: scheduleDataSetName, dbField: 'id', type: 'id', isEdit: false },
    'createdDate': { dataSetName: scheduleDataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName: scheduleDataSetName, dbField: 'created_by' },
    'createdById': { dataSetName: scheduleDataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName: scheduleDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: scheduleDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: scheduleDataSetName, dbField: 'modified_by_id', type: 'id' },
    'startDate': {
      dataSetName: scheduleDataSetName,
      dbField: 'start_date',
      type: 'date',
      parse: (item) => {
        if (Date.parse(item.start_date?.toString?.())) {
          return new Date(item.start_date)
        }

        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
        return today
      },
      isEdit: true,
      isTimezoned: false,
    },
    'avoidFractions': {
      dataSetName: scheduleDataSetName,
      dbField: 'avoid_fractions',
      type: 'boolean',
      defaultValue: false,
      isEdit: true,
    },
    'occurences': {
      dataSetName: scheduleDataSetName,
      dbField: 'occurences',
      type: 'integer',
      defaultValue: 1,
      isEdit: true,
    },
    'periodicity': {
      dataSetName: scheduleDataSetName,
      dbField: 'periodicity',
      type: 'string',
      defaultValue: 'daily',
      isEdit: true,
    },
    'frequency': {
      dataSetName: scheduleDataSetName,
      dbField: 'frequency',
      type: 'integer',
      defaultValue: 1,
      isEdit: true,
    },
    'weeklyWeekdays': {
      dataSetName: scheduleDataSetName,
      dbField: 'weekly_weekdays',
      type: 'array',
      defaultValue: [],
      isEdit: true,
    },
    'monthlyMode': {
      dataSetName: scheduleDataSetName,
      dbField: 'monthly_mode',
      type: 'string',
      defaultValue: 'day',
      isEdit: true,
    },
    'monthlyDay': {
      dataSetName: scheduleDataSetName,
      dbField: 'monthly_day',
      type: 'integer',
      defaultValue: 1,
      isEdit: true,
    },
    'monthlyWeekday': {
      dataSetName: scheduleDataSetName,
      dbField: 'monthly_weekday',
      type: 'string',
      defaultValue: 'monday',
      isEdit: true,
    },
    'monthlyWeekdayRank': {
      dataSetName: scheduleDataSetName,
      dbField: 'monthly_weekday_rank',
      type: 'string',
      defaultValue: 'first',
      isEdit: true,
    },
    'mode': {
      dataSetName: scheduleDataSetName,
      dbField: 'mode',
      type: 'string',
      defaultValue: 'endAfter',
      isEdit: true,
    },
    'salesOrderLineItemRels': {
      parse: (schedule, options: any) => (schedule.sales_order_line_item_rels || [])
        .map((relation) => parseScheduleSalesOrderItemRel(relation, options)),
    },
  }
}

export function getScheduleSalesOrderItemRelFields():
  GetFields<ScheduleSalesOrderItemRelApi, ScheduleSalesOrderItemRel, OptionsType> {
  return {
    'id': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'id', type: 'id', isEdit: false },
    'createdDate': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'created_date', type: 'date' },
    'createdBy': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'created_by' },
    'createdById': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'created_by_id', type: 'id' },
    'modifiedDate': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'modified_date', type: 'date' },
    'modifiedBy': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'modified_by' },
    'modifiedById': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'modified_by_id', type: 'id' },
    'scheduleId': { dataSetName: scheduleSalesOrderItemRelDataSetName, dbField: 'schedule_id', type: 'id' },
    'salesOrderItemId': {
      dataSetName: scheduleSalesOrderItemRelDataSetName,
      dbField: 'sales_order_item_id',
      type: 'id',
      isEdit: true,
      relationEntity: 'sales-order-items',
    },
    'fixedQuantity': {
      dataSetName: scheduleSalesOrderItemRelDataSetName,
      dbField: 'fixed_quantity',
      type: 'float',
      isEdit: true,
    },
    'occurences': {
      dataSetName: scheduleSalesOrderItemRelDataSetName,
      dbField: 'occurences',
      type: 'integer',
      defaultValue: 1,
      isEdit: true,
    },
    'replacable': {
      dataSetName: scheduleSalesOrderItemRelDataSetName,
      dbField: 'replacable_measure',
      type: 'float',
    },
    'salesOrderItem': {
      dataSetName: scheduleSalesOrderItemRelDataSetName,
      dbField: 'sales_order_item',
      parse: (relation, options) => parseSalesOrderLineItem(
        relation.sales_order_item,
        {
          defaultUnits: options?.defaultUnits,
          isPrimaryLanguage: options?.isPrimaryLanguage,
          isDocumentPrimaryLanguage: options?.isDocumentPrimaryLanguage,
          taxDict: options?.taxDict,
          priceMaxDigits: options?.priceMaxDigits,
          measureMaxDigits: options?.measureMaxDigits,
        },
      ),
    },
  }
}

export async function fetchShipmentInfoFromSchedule(schedule: Schedule, options = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      schedule: {
        ...dataToDbData({
          ...schedule,
          startDate: dateToTimezoneString(moment(schedule.startDate)),
        }, getScheduleFields()),
        sales_order_line_item_rels: schedule.salesOrderLineItemRels
          .map((relation) => ({
            ...dataToDbData(relation, initialState.scheduleSalesOrderItemRelFields, true),
            sales_order_item: dataToDbData(relation.salesOrderItem, getSalesOrderLineItemFields(), true),
          })),
      },
      options,
    }),
  }

  let shipmentDict = {}
  let shipmentNumbers = []

  try {
    const { isSuccess, result } = await (await safeFetch(
      '/new_api/shipments/schedules/shipment-info', requestOptions)).json()
    if (isSuccess) {
      const salesOrderLineItemToShipmentLineItemColumns = [
        'description', 'sku', 'templateTitle', 'dimension', 'unit', 'name', 'templateId',
        'manufacturingOrderFormattedNumber', 'type',
      ]

      const salesOrderItemDict = buildDictionary(
        schedule.salesOrderLineItemRels.map((relation) => relation.salesOrderItem),
        'id',
      )
      shipmentDict = Object.keys(result.shipmentDict).reduce((acc, key) => {
        acc[key] = parseShipment(result.shipmentDict[key], true)
        acc[key].lineItems = result.shipmentDict[key].lineItems.map((line: any) => {
          const parsedLine = parseShipmentItem(line, {}, true)
          const relatedLine = salesOrderItemDict[parsedLine.salesOrderItemId]
          salesOrderLineItemToShipmentLineItemColumns.forEach((field) => {
            parsedLine[field] = relatedLine[field]
          })
          return parsedLine
        })

        return acc
      }, {} as any)
      shipmentNumbers = result.shipmentNumbers
    }
  } catch (err) {
    console.error(err)
  }

  return { shipmentDict, shipmentNumbers }
}

export function fetchShipmentsCount(data: Record<string, any>) {
  return async function fetchShipmentsCountThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/shipments/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_SHIPMENTS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export async function fetchProForma(shipmentId: string) {
  let proForma = getDefaultProForma()
  if (!shipmentId) return proForma

  try {
    const { result } = await (await safeFetch(buildGetUrl(`/new_api/shipments/${shipmentId}/pro-forma`))).json()
    proForma = parseProForma(result)
  } catch (err) {
    console.error(err)
  }

  return proForma
}

export async function fetchShipmentsByIds(ids: any[], data: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ShipmentApi>(
    buildGetUrl(`/new_api/shipments/${ids}`, data),
  )

  return isSuccess && !isJob(result) ? result.map((shipment) => parseShipment(shipment)) : []
}

export function fetchShipments(data: Record<string, any>) {
  return async function fetchShipmentsThunk(dispatch: AppDispatch) {
    let shipments = []

    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/shipments', data))).json()
      if (result.isSuccess) {
        shipments = result.result.map((shipment: ShipmentApi) => parseShipment(shipment))
        dispatch({ type: GET_SHIPMENTS, payload: shipments })
      }
    } catch (err) {
      console.error(err)
    }

    return shipments
  }
}

export async function fetchNextNumber(quantity = 1): Promise<{number: number, string: string}[]> {
  let nextNumber = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/shipments/next-number', { quantity }))).json()
    if (result.isSuccess) {
      nextNumber = result.result
    }
  } catch (err) {
    console.error(err)
  }

  return nextNumber
}

export function updateShipment(shipmentId: string, data: Record<string, any>&{refreshReportData?: RefreshReportData}) {
  return async function updateShipmentThunk(dispatch: AppDispatch) {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify( data ),
    }

    try {
      const result = await (await safeFetch(`/new_api/shipments/${shipmentId}`, requestOptions)).json()

      const [payload] = result.isSuccess ? result.result : []
      const error = !result.isSuccess ? parseError(result.result) : null
      dispatch({ type: UPDATE_SHIPMENT, payload, error })
      return { isSuccess: result.isSuccess, error, payload }
    } catch (error) {
      const parsedError = parseError(error)
      dispatch({ type: UPDATE_SHIPMENT, error: parsedError.result })
      return { isSuccess: parsedError.isSuccess, error: parsedError.result }
    }
  }
}

export function updateShipments(shipments: Shipment[]) {
  return async function updateShipmentsThunk(dispatch: AppDispatch) {
    dispatch({ type: GET_SHIPMENTS, payload: shipments })
  }
}

export function updateShipmentItems(shipmentItems: ShipmentLineItem[]) {
  return async function updateShipmentItemsThunk(dispatch: AppDispatch) {
    dispatch({ type: GET_SHIPMENT_ITEMS, payload: shipmentItems })
  }
}

export function clearShipments(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_SHIPMENTS })
}

export function fetchShipmentItemsCount(data: Record<string, any>) {
  return async function fetchShipmentItemsCountThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch(buildGetUrl('/new_api/shipments/line-items/count', data))).json()
      if (result.isSuccess) {
        const count = +result.result[0].count || 0
        dispatch({ type: GET_SHIPMENT_ITEMS_COUNT, payload: count })
        return count
      }

      return 0
    } catch (err) {
      console.error(err)
      return 0
    }
  }
}

export function fetchShipmentItems(data: Record<string, any>, mapData: MapData) {
  return async function fetchShipmentItemsThunk(dispatch: AppDispatch) {
    let shipmentItems = []

    try {
      const { result, isSuccess } = await safeFetchJson<ShipmentLineItemApi>(
        buildGetUrl('/new_api/shipments/line-items', data),
      )

      if (isSuccess && !isJob(result)) {
        shipmentItems = result.map((shipmentItem) => parseShipmentItem(shipmentItem, mapData))
        dispatch({ type: GET_SHIPMENT_ITEMS, payload: shipmentItems })
      }
    } catch (err) {
      console.error(err)
    }

    return shipmentItems
  }
}

export async function fetchShipmentItemsbyIds(ids: any[], data: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<ShipmentLineItemApi>(`/new_api/shipments/line-items/${ids}`)

  return isSuccess && !isJob(result) ? result.map((shipmentItem) => parseShipmentItem(shipmentItem, data)) : []
}

export function createShipments(shipments: ShipmentApi[], refreshReportData?: RefreshReportData) {
  return async function createShipmentsThunk(dispatch: AppDispatch) {
    const result = await _createShipments(shipments, refreshReportData)

    dispatch({ type: CREATE_SHIPMENT, ...result })

    return result
  }
}

/**
 * Handles schedule save (create, replace and toasters)
 * @param {Object} schedule
 * @param {Object} shipmentDict
 * @param {Object} processOptions
 * @param {Function} [processOptions.setToastState] Opens and closes process toast on save resolve (required)
 * @param {Object} [processOptions.clientSocket] Client socket used to resolve job (required)
 * @returns middleware
 */
export function saveSchedule(
  schedule: Schedule,
  shipmentDict: Record<string, Shipment>,
  processOptions: Record<string, any>,
) {
  processOptions.setToastState((state: any) => ({ ...state, processing: { ...state.processing, active: true } }))

  const isFixedQuantityLine = schedule.mode === 'fixedQuantityPerLine'
  const newSchedule = {
    ...dataToDbData(schedule, initialState.scheduleFields),
    sales_order_line_item_rels: schedule.salesOrderLineItemRels.map((relation) => dataToDbData(
      {
        salesOrderItemId: relation.salesOrderItem.id,
        fixedQuantity: isFixedQuantityLine ? relation.fixedQuantity : undefined,
        occurences: relation.occurences,
      },
      initialState.scheduleSalesOrderItemRelFields,
    )),
    shipments: Object.keys(shipmentDict).map((key) => {
      const _shipment = shipmentDict[key]
      return {
        ...dataToDbData(_shipment, initialState.fields),
        lineItems: _shipment.lineItems.map((lineItem) => dataToDbData(lineItem, initialState.itemFields)),
      }
    }),
  }

  return async function saveScheduleThunk(dispatch: AppDispatch, getState: any) {
    const _processOptions = { ...processOptions, dispatch, getState }
    if (schedule.id) {
      return _replaceSchedule(String(schedule.id), newSchedule, _processOptions)
    }

    return _createSchedule(newSchedule, _processOptions)
  }
}

function _handleScheduleJobResult(result: any, processOptions: any) {
  if (result.isSuccess) {
    if (result.result.isTimeout) {
      const listenerString = `/job/${result.result.jobUUID}`
      const listener = () => _resolveScheduleJob(true, processOptions, result)
      processOptions.dispatch({ type: SET_SCHEDULE_LISTENER, payload: { jobUUID: result.result.jobUUID, listener } })
      processOptions.clientSocket.on(listenerString, listener)
    } else {
      _resolveScheduleJob(true, processOptions)
    }
  } else {
    _resolveScheduleJob(false, processOptions, result)
  }
}

function _resolveScheduleJob(
  isSuccess: boolean,
  processOptions: Record<string, any>,
  result: Record<string, any> = {},
) {
  const jobUUID = result?.result?.jobUUID

  if (jobUUID) {
    const listenerString = `/job/${jobUUID}`
    const listener = processOptions.getState().shipments.scheduleListeners[jobUUID]
    processOptions.clientSocket.removeListener(listenerString, listener)
    processOptions.dispatch({ type: CLEAR_SCHEDULE_LISTENER, payload: { jobUUID } })
  }

  const toast = () => processOptions.setToastState((state: Record<string, any>) => ({
    ...state,
    processing: { ...state.processing, active: false },
    success: isSuccess ? { ...state.success, active: true } : state.success,
    error: !isSuccess ? { ...state.error, ...result.result, active: true } : state.error,
  }))

  if (isSuccess) {
    _fetchSalesOrder(processOptions.salesOrderId, processOptions.mapData)
      .then((updated) => {
        processOptions.dispatch({ type: UPDATE_SALES_ORDER, payload: { ...updated, _skipToast: true } })
        processOptions.onSuccess?.()
        toast()
      })
  } else {
    toast()
  }
}

async function _createSchedule(schedule: Record<string, any>, processOptions: Record<string, any>) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ schedule }),
  }

  try {
    const result = await (await safeFetch('/new_api/shipments/schedules', requestOptions)).json()
    _handleScheduleJobResult(result, processOptions)
  } catch (error) {
    _handleScheduleJobResult({ isSuccess: false, result: error }, processOptions)
  }
}

async function _replaceSchedule(
  oldScheduleId: string,
  newSchedule: Record<string, any>,
  processOptions: Record<string, any>,
) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ schedule: newSchedule }),
  }

  try {
    const result = await (await safeFetch(`/new_api/shipments/schedules/${oldScheduleId}`, requestOptions)).json()
    _handleScheduleJobResult(result, processOptions)
  } catch (error) {
    _handleScheduleJobResult({ isSuccess: false, result: error }, processOptions)
  }
}

/**
 * Handles schedule delete and toasters
 * @param {String} scheduleId
 * @param {Object} processOptions
 * @param {Function} [processOptions.setToastState] Opens and closes process toast on save resolve (required)
 * @param {Object} [processOptions.clientSocket] Client socket used to resolve job (required)
 * @returns middleware
 */
export function deleteSchedule(scheduleId: string, processOptions: Record<string, any>) {
  processOptions.setToastState((state: any) => ({ ...state, processing: { ...state.processing, active: true } }))

  return async function deleteScheduleThunk(dispatch: AppDispatch, getState: any) {
    return _deleteSchedule(scheduleId, { ...processOptions, dispatch, getState })
  }
}

async function _deleteSchedule(scheduleId: string, processOptions: Record<string, any>) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  try {
    const result = await (await safeFetch(`/new_api/shipments/schedules/${scheduleId}`, requestOptions)).json()
    _handleScheduleJobResult(result, processOptions)
  } catch (error) {
    _handleScheduleJobResult({ isSuccess: false, result: error }, processOptions)
  }
}

export async function _createShipments(shipments: ShipmentApi[], refreshReportData?: RefreshReportData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ shipments /* resourceId */, refreshReportData }),
  }

  try {
    const result = await (await safeFetch('/new_api/shipments', requestOptions)).json()

    const error = !result.isSuccess ? result.result : null

    const payload = result.result.map((shipment: ShipmentApi) => parseShipment(shipment))
    return { isSuccess: result.isSuccess, payload, error }
  } catch (error) {
    return { isSuccess: false, payload: [], error }
  }
}

export function deleteShipment(shipmentId: string) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function deleteShipmentsThunk(dispatch: AppDispatch) {
    try {
      const result = await (await safeFetch(`/new_api/shipments/${shipmentId}`, requestOptions)).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_SHIPMENT, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_SHIPMENT, error })
    }
  }
}

export function clearShipmentItems(dispatch: AppDispatch) {
  dispatch({ type: CLEAR_SHIPMENT_ITEMS })
}

export function parseShipment(shipment: ShipmentApi, onlyParseAvailableFields = false): Shipment {
  const options = {
    defaultData: getDefaultShipment(),
    fields: initialState.fields,
    dataSetName,
    onlyParseAvailableFields,
  }

  return parse(shipment, options)
}

export function parseProForma(proForma: ProFormaApi): ProForma {
  const options = {
    defaultData: getDefaultProForma(),
    fields: initialState.proFormaFields,
    dataSetName: proFormaDataSetName,
  }

  return parse(proForma, options)
}

function parseLineItems(proForma: ProFormaApi): ProFormaItem[] {
  if (!proForma.lineItems?.length) return []
  const options = {
    defaultData: getDefaultProFormaItems(),
    fields: getProFormaItemsFields(),
    dataSetName: proFormaItemsDataSetName,
  }
  return proForma.lineItems.map((item) => parse(item, options))
}

function parseRate(lineItem: ProFormaItemApi) {
  const unitPrice = +convertFromDollarPerBase(
    lineItem.dimension,
    lineItem.unit_price,
    lineItem.unit,
  )
  return unitPrice - (unitPrice * lineItem.discount / 100)
}

function parseAmount(lineItem: ProFormaItemApi) {
  const rate = lineItem.unit_price - (lineItem.unit_price * lineItem.discount / 100)
  const quantity = lineItem.quantity_picked || lineItem.total_quantity
  return rate * quantity
}

export function parseSchedule(schedule: ScheduleApi, options = {}): Schedule {
  const _options = {
    ...options,
    defaultData: getDefaultSchedule(),
    fields: initialState.scheduleFields,
    dataSetName: scheduleDataSetName,
  }

  return parse(schedule, _options)
}

function parseScheduleSalesOrderItemRel(scheduleSalesOrderItemRel: ScheduleSalesOrderItemRelApi, options = {}):
  ScheduleSalesOrderItemRel {
  const _options = {
    ...options,
    defaultData: getDefaultScheduleSalesOrderItemRel(),
    fields: initialState.scheduleSalesOrderItemRelFields,
    dataSetName: scheduleSalesOrderItemRelDataSetName,
  }

  return parse(scheduleSalesOrderItemRel, _options)
}

function getDefaultShipment() {
  return parse({}, { fields: initialState.fields })
}

function getDefaultSchedule() {
  return parse({}, { fields: initialState.scheduleFields })
}

function getDefaultScheduleSalesOrderItemRel() {
  return parse({}, { fields: initialState.scheduleSalesOrderItemRelFields })
}

function getDefaultProForma(): ProForma {
  return parse({}, { fields: initialState.proFormaFields })
}

function getDefaultProFormaItems() {
  return parse({}, { fields: initialState.proFormaItemsFields })
}

export function getShipmentTitle(shipment: Shipment) {
  return shipment.formattedNumber
}

export function getShipmentItemTitle(shipmentItem: ShipmentLineItem) {
  return shipmentItem.shipment
}

