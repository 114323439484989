
import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, safeFetchJson } from 'utils/safeFetch'

import { GET_LOCATIONS, GET_LOCATIONS_COUNT } from './types'

const dataSetName = 'locationView'
const fields = getFields()
const initialState = { dataSetName, fields }

export default function locationsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_LOCATIONS_COUNT: {
    return {
      ...state,
      locationsCount: payload,
    }
  }
  case GET_LOCATIONS: {
    return {
      ...state,
      locations: payload,
    }
  }
  default: {
    return state
  }
  }
}

export function getDisplayTitleFromApi({ title, code }) {
  return title || code
}

export function getFields() {
  return {
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'tag': { dataSetName, dbField: 'private_tag', type: 'string' },
    'name': { dataSetName, dbField: 'title', type: 'string' },
    'code': { dataSetName, dbField: 'code', type: 'string' },
    'title': { dataSetName, dbField: 'title', type: 'string' },
    'zoneTitle': { dataSetName, dbField: 'zone_title', type: 'string' },
    'zoneCode': { dataSetName, dbField: 'zone_code', type: 'string' },
    'aisleCode': { dataSetName, dbField: 'aisle_code', type: 'string' },
    'bayCode': { dataSetName, dbField: 'bay_code', type: 'string' },
    'levelCode': { dataSetName, dbField: 'level_code', type: 'string' },
    'warehouseTitle': { dataSetName, dbField: 'warehouse_title', type: 'string' },
    'warehouseCode': { dataSetName, dbField: 'warehouse_code', type: 'string' },
    'configName': { parse: _parseConfigName, type: 'string' },
    'displayTitle': { parse: getDisplayTitleFromApi },
  }
}

export async function fetchLocationByIds(ids, data) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson(
    buildGetUrl(`/new_api/locations/${ids}`, data),
  )

  return isSuccess ? result.map((item) => parseLocation(item)) : []
}

export function getLocationTitle(location) {
  return location.displayTitle
}

export function fetchLocationsCount(data) {
  return async function fetchLocationsCountThunk(dispatch) {
    const count = await _fetchLocationsCount(data)
    dispatch({ type: GET_LOCATIONS_COUNT, payload: count })
    return count
  }
}

export async function _fetchLocationsCount(data) {
  let count = 0

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/locations/count', data))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchLocations(data) {
  return async function fetchLocationThunk(dispatch) {
    const locations = await _fetchLocations(data)
    dispatch({ type: GET_LOCATIONS, payload: locations })
    return locations
  }
}

export async function _fetchLocations(data, mapData) {
  let locations = []

  try {
    const result = await (await safeFetch(buildGetUrl('/new_api/locations', data))).json()
    if (result.isSuccess) {
      locations = result.result.map((location) => parseLocation(location, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return locations
}

export async function _fetchLocationIndex(id, data = {}) {
  let index = 0

  if (id) {
    try {
      const result = await (await safeFetch(buildGetUrl(`/new_api/locations/${id}/index`, data))).json()
      if (result.isSuccess) {
        index = +result.result || 0
      }
    } catch (err) {
      console.error(err)
    }
  }

  return index
}

function _parseConfigName(location, options) {
  const locationCode = location.code || ''
  const locationTitle = location.title || ''
  const isCode = typeof options.is_code === 'boolean' ? options.is_code : true
  const isName = typeof options.is_name === 'boolean' ? options.is_name : true

  let displayTitle = locationTitle || locationCode || 'N/A'
  if (isCode && isName) {
    displayTitle = `${locationCode} | ${locationTitle}`
    displayTitle = displayTitle.trim() == '|' ? '' : displayTitle
  } else if (isCode || isName) {
    displayTitle = isCode ? locationCode : locationTitle
  }
  return displayTitle
}

export function parseLocation(location, mapData = {}) {
  const options = {
    defaultData: getDefaultLocation(),
    fields: initialState.fields,
    dataSetName,
    ...mapData,
  }
  return parse(location, options)
}

function getDefaultLocation() {
  return parse({}, { fields })
}

/**
 * @returns {import('reducers/smart-form/smartFormTypes').SmartFormOptionObject}
 */
export const getLocationDropdownOptions = ({ fetchData = {} } = { fetchData: {} }) => {
  return {
    key: 'locations',
    fetchOnFirstOpen: true,
    fetcher: _fetchLocations,
    indexFetcher: _fetchLocationIndex,
    countFetcher: _fetchLocationsCount,
    fields: initialState.fields,

    fetchData: {
      ...fetchData,
      exist: true,
    },
    filterFieldKeys: ['code', 'title'],
    isLazy: true,
    orderByFieldKeys: [
      {
        key: 'code',
      },
      {
        key: 'title',
      },
      {
        key: 'id',
        isNullFirst: true,
      },
    ],
  }
}

