import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetchJson, parsedResultOnSucessOrEmtpy } from 'utils/safeFetch'

import { fetchTemplateSupplierPricesParam } from 'reducers/template-supplier-prices/templateSupplierPricesSlice'

const dataSetName = 'templateSupplier'

export type TemplateSupplierApi = Modify<{
  contact_display_name: string
  conversion_factor: number
  discount: number
  list_price: number
  minimum_order_qty: number
  unit_cost: number
  supplier_id: string
  template_id: string
  templateview_description: string
  purchasing_lead_time: number
  ranking: number
  custom_unit: string
  description: string
  long_description: string
  notes: string
  part_number: string
  purchasing_lead_time_unit: string
}, BaseEntityApi>

type Exceptions = {
  contact_display_name: 'name'
  templateview_description: 'templateDescription'
}

export type TemplateSupplier = ApiToSlice<TemplateSupplierApi, Exceptions>

export function getFields(): GetFields<TemplateSupplierApi, TemplateSupplier> {
  return {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },

    'conversionFactor': { dataSetName, dbField: 'conversion_factor', type: 'float' },
    'discount': { dataSetName, dbField: 'discount', type: 'float' },
    'listPrice': { dataSetName, dbField: 'list_price', type: 'float' },
    'minimumOrderQty': { dataSetName, dbField: 'minimum_order_qty', type: 'float' },
    'unitCost': { dataSetName, dbField: 'unit_cost', type: 'float' },

    'cid': { dataSetName, dbField: 'cid', type: 'id' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'id' },
    'id': { dataSetName, dbField: 'id', type: 'id' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'id' },
    'supplierId': { dataSetName, dbField: 'supplier_id', type: 'id' },
    'templateId': { dataSetName, dbField: 'template_id', type: 'id' },
    'templateDescription': { dataSetName, dbField: 'templateview_description', type: 'string' },

    'purchasingLeadTime': { dataSetName, dbField: 'purchasing_lead_time', type: 'integer' },
    'ranking': { dataSetName, dbField: 'ranking', type: 'integer' },

    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'customUnit': { dataSetName, dbField: 'custom_unit', type: 'string' },
    'description': { dataSetName, dbField: 'description', type: 'string' },
    'longDescription': { dataSetName, dbField: 'long_description', type: 'string' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'name': { dataSetName, dbField: 'contact_display_name', type: 'string' },
    'notes': { dataSetName, dbField: 'notes', type: 'string' },
    'partNumber': { dataSetName, dbField: 'part_number', type: 'string' },
    'purchasingLeadTimeUnit': { dataSetName, dbField: 'purchasing_lead_time_unit', type: 'string' },

    'createdDate': { dataSetName, dbField: 'created_date', type: 'date' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'date' },
  }
}

const initialState = {
  fields: getFields(),
  data: [] as TemplateSupplier[],
  count: 0,
}

export function parseTemplateSupplier(templateSupplier: TemplateSupplierApi) {
  const fields = initialState.fields

  const options = {
    fields: fields,
    dataSetName,
  }
  return parse(templateSupplier, options)
}

export async function fetchTemplateSuppliers(data: fetchTemplateSupplierPricesParam): Promise<TemplateSupplier[]> {
  const response = await safeFetchJson(buildGetUrl(`/new_api/inventories/templates/suppliers/`, data))

  return parsedResultOnSucessOrEmtpy(response, parseTemplateSupplier)
}
export async function getTemplateSuppliersById(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const response = await safeFetchJson<TemplateSupplierApi>(
    buildGetUrl(`/new_api/inventories/templates/suppliers/${ids}`, data),
  )

  return parsedResultOnSucessOrEmtpy(response, parseTemplateSupplier)
}

export function getTemplateSupplierTitle(templateSupplier: TemplateSupplier): string {
  return templateSupplier.templateDescription
}
