import { prefixFieldFromPrefix } from 'components/alix-front/legacy-smart-grid/columns/utils'

import { buildGetUrl, parse } from 'utils/api'
import { safeFetch, parsedResultOnSucessOrEmtpy } from 'utils/safeFetch'
import { toCamel } from 'utils/stringUtils'
import { convertToBase, convertFromBase, convertFromDollarPerBase } from 'utils/unitConverter'

import { parseContact } from 'reducers/contacts/contactsSlice'
import {
  buildReportingTagsFormState,
  buildReportingTagsFormStateWithDeletions,
  buildReportingTagsState,
  getDefaultReportingTagsForm,
  getReportingTagFields,
  parseReportingTag,
} from 'reducers/reporting-tags/reportingTagAssociationSlice'

import {
  GET_ITEMS_COUNT,
  GET_ITEMS,
  CLEAR_ITEMS,
  DELETE_ITEM,
  CREATE_ITEM,
  COPY_ITEM,
  SET_ITEM_REPORTING_TAGS_FORM,
  INIT_ITEM_REPORTING_TAGS_FORM,
  SET_ITEM_REPORTING_TAGS_FORM_SELECTION,
  DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION,
  UPDATE_ITEM,
} from './types'

const dataSetName = 'templateView'
const itemVendorDataSetName = 'templateSupplier'
const recipeDataSetName = 'item'
const entityName = 'items'

const initialState = {
  dataSetName,
  fields: getFields(),
  itemVendorFields: getVendorFields(),
  recipeFields: getRecipeFields(),
  reportingTagFields: getReportingTagFields(entityName),
  reportingTagsForm: getDefaultReportingTagsForm(entityName),
  itemsCount: 0,
  items: [],
}

export default function itemsReducer(state = initialState, action) {
  const { payload } = action
  switch (action.type) {
  case GET_ITEMS_COUNT: {
    const itemsCountKey = payload.itemsCountKey || 'itemsCount'
    return {
      ...state,
      [itemsCountKey]: payload.count,
    }
  }
  case GET_ITEMS: {
    return {
      ...state,
      items: payload,
    }
  }
  case CLEAR_ITEMS: {
    return {
      ...state,
      items: [],
      itemsCount: 0,
    }
  }
  case UPDATE_ITEM: {
    if (!payload?.reportingTags) return state
    return buildReportingTagsState(state, payload.reportingTags, entityName)
  }
  case SET_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsFormState(state, payload)
  }
  case INIT_ITEM_REPORTING_TAGS_FORM: {
    return buildReportingTagsState(state, payload, entityName)
  }
  case SET_ITEM_REPORTING_TAGS_FORM_SELECTION: {
    return buildReportingTagsFormState(state, { ...state.reportingTagsForm.reportingTags, selections: payload })
  }
  case DELETE_ITEM_REPORTING_TAGS_FROM_SELECTION: {
    return buildReportingTagsFormStateWithDeletions(state)
  }
  default: {
    return state
  }
  }
}

function getVendorFields() {
  return {
    id: { dataSetName: itemVendorDataSetName, dbField: 'id' },
    createdDate: { dataSetName: itemVendorDataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName: itemVendorDataSetName, dbField: 'created_by' },
    modifiedDate: { dataSetName: itemVendorDataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName: itemVendorDataSetName, dbField: 'modified_by' },
    conversionFactor: { dataSetName: itemVendorDataSetName, dbField: 'conversion_factor' },
    customUnit: { dataSetName: itemVendorDataSetName, dbField: 'custom_unit' },
    description: { dataSetName: itemVendorDataSetName, dbField: 'description' },
    longDescription: { dataSetName: itemVendorDataSetName, dbField: 'long_description' },
    partNumber: { dataSetName: itemVendorDataSetName, dbField: 'part_number' },
    rank: { dataSetName: itemVendorDataSetName, dbField: 'ranking' },
    moq: { dataSetName: itemVendorDataSetName, dbField: 'minimum_order_qty' },
    listPrice: { dataSetName: itemVendorDataSetName, dbField: 'list_price' },
    discount: { dataSetName: itemVendorDataSetName, dbField: 'discount' },
    unitCost: { dataSetName: itemVendorDataSetName, dbField: 'unit_cost' },
    purchasingLeadTime: { dataSetName: itemVendorDataSetName, dbField: 'purchasing_lead_time' },
    purchasingLeadTimeUnit: { dataSetName: itemVendorDataSetName, dbField: 'purchasing_lead_time_unit' },
    vendorId: { dataSetName: itemVendorDataSetName, dbField: 'supplier_id' },
    templateId: { dataSetName: itemVendorDataSetName, dbField: 'template_id' },
    currencyCode: { dataSetName: 'currency', dbField: 'currency_code' },
    currencySymbol: { dataSetName: 'currency', dbField: 'currency_symbol' },
    currencyExchangeRate: { dataSetName: 'currency', dbField: 'exchange_rate' },
    currencyIsBase: { dataSetName: 'currency', dbField: 'is_base_currency' },
    vendor: { parse: (itemVendor) => itemVendor.vendor },
  }
}

export function getVendorFieldsForPlanning() {
  // ! Be careful to use options.prefixItemField when accessing a property on options.relatedItem.
  return {
    id: { dataSetName: itemVendorDataSetName, dbField: 'template_id' },
    customUnit: { dataSetName: itemVendorDataSetName, dbField: 'custom_unit' },
    purchasingLeadTime: { dataSetName: itemVendorDataSetName, dbField: 'purchasing_lead_time' },
    purchasingLeadTimeUnit: { dataSetName: itemVendorDataSetName, dbField: 'purchasing_lead_time_unit' },
    exchangeRate: { dataSetName: 'currency', dbField: 'exchange_rate', defaultValue: 1 },
    vendorPrices: {
      parse: (itemVendor) => {
        return itemVendor.prices?.map((prices) => ({
          minimumOrderQty: prices.minimum_order_qty,
          listPrice: prices.list_price,
          unitCost: prices.unit_cost,
          discount: prices.discount,
        }))
      },
    },
    vendorListPrice: {
      dataSetName: itemVendorDataSetName,
      dbField: 'list_price',
      parse: (itemVendor) => (+itemVendor.list_price || 0)*(+itemVendor.currency_exchange_rate || 1),
    },
    vendorUnitCost: {
      dataSetName: itemVendorDataSetName,
      dbField: 'unit_cost',
      parse: (itemVendor) => (+itemVendor.unit_cost || 0)*(+itemVendor.currency_exchange_rate || 1),
    },
    vendorDiscount: { dataSetName: itemVendorDataSetName, dbField: 'discount', defaultValue: 0 },
    unitCost: {
      dataSetName: itemVendorDataSetName,
      dbField: 'unit_cost',
      parse: (itemVendor) => (+itemVendor.unit_cost || 0)*(+itemVendor.currency_exchange_rate || 1),
    },
    initialConversionFactor: { dataSetName: itemVendorDataSetName, dbField: 'conversion_factor', defaultValue: 0 },
    conversionFactor: {
      dataSetName: itemVendorDataSetName,
      dbField: 'conversion_factor',
      parse: (itemVendor, options) => getSingleToBase(
        {
          dimension_to_display: options.relatedItem[options.prefixItemField('dimension')],
          measure_unit: options.relatedItem[options.prefixItemField('measureUnit')],
        },
        options,
      ),
    },
    title: { parse: (itemVendor, options) => options.relatedItem[options.prefixItemField('templateTitle')] },
    dimension: { parse: (itemVendor, options) => options.relatedItem[options.prefixItemField('dimension')] },
    partNumber: {
      parse: (itemVendor, options) => (
        itemVendor.part_number || options.relatedItem[options.prefixItemField('partNumber')]
      ),
    },
    initialMeasureUnit: { parse: (itemVendor, options) => (
      options.relatedItem[options.prefixItemField('measureUnit')] ||
      options.defaultUnits[options.relatedItem[options.prefixItemField('dimension')]]
    ) },
    descriptionToVendorLanguage: { parse: (itemVendor, options) => getDescriptionToVendorLanguage(
      options.relatedItem,
      {
        vendorDetails: itemVendor.vendor,
        preferredCompany: options.preferredCompany,
        matchingVendor: itemVendor,
        isCamel: true,
        prefixItemField: options.prefixItemField,
      },
    ) },
    longDescriptionToVendorLanguage: { parse: (itemVendor, options) => getLongDescriptionToVendorLanguage(
      options.relatedItem,
      {
        vendorDetails: itemVendor.vendor,
        preferredCompany: options.preferredCompany,
        matchingVendor: itemVendor,
        isCamel: true,
        prefixItemField: options.prefixItemField,
      },
    ) },
    measure: { parse: (itemVendor, options) => Math.max(
      +options.relatedItem.baseRecommendation,
      +itemVendor.minimum_order_qty || +options.relatedItem[options.prefixItemField('initialMeasure')],
    ) },
    measureUnit: { parse: (itemVendor, options) => getItemMeasureUnit(
      {
        dimension_to_display: options.relatedItem[options.prefixItemField('dimension')],
        measure_unit: options.relatedItem[options.prefixItemField('measureUnit')],
      },
      options,
    ) },
    purchaseMoq: { parse: (itemVendor, options) => (
      +itemVendor.minimum_order_qty || +options.relatedItem[options.prefixItemField('initialMeasure')]
    ) },
    currencyCode: { parse: (itemVendor, options) => options.baseCurrency.code },
    currencySymbol: { parse: (itemVendor, options) => options.baseCurrency.symbol },
    vendor: { parse: (itemVendor) => itemVendor.vendor },
  }
}

export function getFields() {
  return {
    id: { dataSetName, dbField: 'id' },
    exist: { dataSetName, dbField: 'exist' },
    dimension: { dataSetName, dbField: 'dimension_to_display' },
    dimensionOrder: { dataSetName, dbField: 'dimension_order' },
    measureUnit: { parse: getMeasureUnit },
    initialMeasureUnit: { parse: getItemMeasureUnit },
    sellingUnit: { parse: getSellingUnit },
    title: { dataSetName, dbField: 'template_title' },
    sku: { dataSetName, dbField: 'sku' },
    description: { dataSetName, dbField: 'description' },
    secondaryDescription: { dataSetName, dbField: 'secondary_description',
      customFieldTranslationKey: (t, options) =>
        t('items:item.fields.secondaryDescription', { language: options.secondaryLanguage }),
    },
    instructions: { dataSetName, dbField: 'instructions' },
    trimmedInstructions: { dataSetName, dbField: 'trimmed_instructions', parse: parseTrimmedInstructions },
    descriptionToVendorLanguage: { parse: getDescriptionToVendorLanguage },
    longDescriptionToVendorLanguage: { parse: getLongDescriptionToVendorLanguage },
    primaryLongDescriptionPurchase: { dataSetName, dbField: 'long_description_purchase',
      customFieldTranslationKey: (t, options) =>
        t('items:item.fields.longDescriptionPurchase', { language: options.primaryLanguage }),
    },
    secondaryLongDescriptionPurchase: { dataSetName, dbField: 'long_secondary_description_purchase',
      customFieldTranslationKey: (t, options) =>
        t('items:item.fields.longSecondaryDescriptionPurchase', { language: options.secondaryLanguage }),
    },
    descriptionToCustomerLanguage: { parse: getDescriptionToCustomerLanguage },
    longDescriptionToCustomerLanguage: { parse: getLongDescriptionToCustomerLanguage },
    partNumber: { dataSetName, dbField: 'part_number', parse: parsePartNumber },
    manufacturer: { dataSetName, dbField: 'manufacturer' },
    revision: { dataSetName, dbField: 'revision' },
    moq: { dataSetName, dbField: 'min_selling_measure', parse: parseMoq },
    purchaseMoq: { dataSetName, dbField: 'initial_measure', parse: parsePurchaseMoq },
    unitCost: { parse: parseUnitCost },
    plannedUnitCost: { dataSetName, dbField: 'planned_unit_cost', isEdit: true },
    calculatedUnitCost: {
      dataSetName,
      dbField: 'calculated_planned_unit_cost',
      parse: (item = {}) => +item.calculated_planned_unit_cost || 0,
    },
    vendorListPrice: { parse: parseVendorListPrice },
    vendorDiscount: { parse: parseVendorDiscount },
    conversionFactor: { parse: getMatchingVendorConversionFactor },
    initialConversionFactor: { parse: (item, options) => getMatchingVendorConversionFactor(item, options, true) },
    sellingPrice: { parse: getSellingPrice },
    convertedSellingPrice: { parse: parseSellingPrice, formDefaultValue: 0 },
    isInPriceList: { parse: (item = {}) => item.is_in_price_list },
    isInAttribute: { parse: (item = {}) => item.is_in_attribute },
    purchaseOrderItemCount: { parse: (item = {}) => item.purchase_order_item_count },
    salesOrderItemCount: { parse: (item = {}) => item.sales_order_item_count },
    treatmentId: { dataSetName, dbField: 'treatment_id', type: 'id', relationEntity: 'treatments' },
    treatment: { dataSetName, dbField: 'treatment_title' },
    material: { dataSetName, dbField: 'material_title' },
    materialId: { dataSetName, dbField: 'material_id', type: 'id', relationEntity: 'materials' },
    categoryId: { dataSetName, dbField: 'category_id', type: 'id', relationEntity: 'categories' },
    category: { dataSetName, dbField: 'category_title' },
    countryOfOrigin: { dataSetName, dbField: 'country_of_origin_primary_name' },
    harmonizedSystemCode: { dataSetName, dbField: 'harmonized_system_code_code' },
    rawImperial: { dataSetName, dbField: 'raw_imperial_title' },
    rawMetric: { dataSetName, dbField: 'raw_metric_title' },
    tag: { dataSetName, dbField: 'private_tag' },
    upc: { dataSetName, dbField: 'upc' },
    ean: { dataSetName, dbField: 'ean' },
    unitedNationsNumber: { dataSetName, dbField: 'united_nations_number' },
    mainVendorId: { dataSetName, dbField: 'main_supplier_contact_id' },
    mainVendor: { dataSetName, dbField: 'main_supplier_contact_display_name' },
    mainVendorPartNumber: { dataSetName, dbField: 'main_supplier_part_number' },
    mainVendorListPrice: {
      dataSetName,
      dbField: 'main_supplier_list_price',
      parse: (item = {}) => +item.main_supplier_list_price || 0,
    },
    mainVendorDiscount: {
      dataSetName,
      dbField: 'main_supplier_discount',
      parse: (item = {}) => +item.main_supplier_discount || 0,
    },
    mainVendorCurrency: { dataSetName, dbField: 'main_supplier_currency_currency_code' },
    mainVendorSymbol: { dataSetName, dbField: 'main_supplier_currency_currency_symbol' },
    mainVendorExchangeRate: {
      dataSetName,
      dbField: 'main_supplier_currency_exchange_rate',
      parse: (item = {}) => +item.main_supplier_currency_exchange_rate || 1,
    },
    mainVendorBaseCost: {
      dataSetName,
      dbField: 'main_supplier_base_cost',
      parse: (item = {}) => +item.main_supplier_base_cost || 0,
    },
    mainVendorConversionFactor: {
      dataSetName,
      dbField: 'main_supplier_conversion_factor',
      parse: getMainConversionFactor,
    },
    mainVendorMeasureUnit: { parse: getMainVendorMeasureUnit },
    inventoryTotal: {
      dataSetName,
      dbField: 'sum',
      dataSetAlias: 'total_measure_inventory',
      parse: (item, options) => {
        const value = +item.total_measure_inventory_sum || 0
        return convertFromBase(item.dimension_to_display, value, getItemMeasureUnit(item, options), false)
      },
    },
    unitWeight: { dataSetName, dbField: 'unit_weight' },
    convertedUnitWeight: { dataSetName, dbField: 'unit_weight', parse: (item = {}, options = {}) => {
      const converted = convertFromBase('weight', +item.unit_weight, getWeightUnit(item, options), true)
      return converted * getSingleToBase(item, options)
    } },
    weightUnit: { parse: getWeightUnit },
    unitSurface: { dataSetName, dbField: 'unit_surface' },
    convertedUnitSurface: { dataSetName, dbField: 'unit_surface', parse: (item = {}, options = {}) => {
      return convertFromBase(
        'surface',
        +item.unit_surface,
        getSurfaceUnit(item, options),
        true,
      ) * getSingleToBase(item, options)
    } },
    surfaceUnit: { parse: getSurfaceUnit },
    unitVolume: { dataSetName, dbField: 'unit_volume' },
    convertedUnitVolume: { dataSetName, dbField: 'unit_volume', parse: (item = {}, options = {}) => {
      const converted = convertFromBase('volume', +item.unit_volume, getVolumeUnit(item, options), true)
      return converted * getSingleToBase(item, options)
    } },
    volumeUnit: { parse: getVolumeUnit },
    createdDate: { dataSetName, dbField: 'created_date', type: 'date' },
    createdBy: { dataSetName, dbField: 'created_by' },
    modifiedDate: { dataSetName, dbField: 'modified_date', type: 'date' },
    modifiedBy: { dataSetName, dbField: 'modified_by' },
    modifiedById: { dataSetName, dbField: 'modified_by_id' },
    groupId: { dataSetName, dbField: 'group_id' },
    group: { dataSetName, dbField: 'group_name' },
    type: { dataSetName, dbField: 'type', customFieldTranslationKey: (t) => t('items:item.fields.type.type') },
    inventoryManagementType: { dataSetName, dbField: 'inventory_management_type' },
    isSelling: { dataSetName, dbField: 'is_selling' },
    isManufactured: { dataSetName, dbField: 'is_manufactured' },
    isPurchased: { dataSetName, dbField: 'is_purchased' },
    locationId: { dataSetName, dbField: 'location_id', type: 'id', relationEntity: 'locations' },
    expirationDelay: { dataSetName, dbField: 'expiration_delay' },
    expirationDelayUnit: { dataSetName, dbField: 'expiration_delay_unit' },
    vendors: { parse: (item) => item.suppliers || [] },
    status: {
      dataSetName,
      dbField: 'status',
      type: 'status',
      isEdit: true,
      values: ['active', 'inactive'],
      defaultValue: 'active',
      parse: (props) => props['status'] ?? 'active',
    },
    isTaxed: { dataSetName, dbField: 'is_taxed', type: 'boolean' },
    manufacturingOrderId: { dataSetName, dbField: 'manufacturing_order_id', type: 'id', relationEntity: 'cards' },
    assetAccountId: {
      dataSetName,
      dbField: 'asset_account_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    expenseAccountId: {
      dataSetName,
      dbField: 'expense_account_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    incomeAccountId: {
      dataSetName,
      dbField: 'income_account_id',
      isEdit: true,
      type: 'id',
      relationEntity: 'chart-of-accounts',
    },
    isAllowedConsignmentCustomer: { dataSetName, dbField: 'is_allowed_consignment_customer', type: 'boolean' },
    plannedTargetInventoryId: {
      dataSetName,
      dbField: 'planned_target_inventory_id',
      type: 'id',
      relationEntity: 'inventories',
    },
    attributeId: {
      dataSetName,
      dbField: 'attribute_id',
      type: 'id',
      relationEntity: 'attributes',
      isEdit: true,
    },
    attributeDescription: { dataSetName, dbField: 'attribute_description', relationEntity: 'attributes' },
    attributeSecondaryDescription: {
      dataSetName,
      dbField: 'attribute_secondary_description',
      relationEntity: 'attributes',
    },
    attributeOptionDecription: {
      dataSetName,
      dbField: 'attribute_option_display_description',
      type: 'string',
      relationEntity: 'attributes',
    },
    attributeOptionSecondaryDescription: {
      dataSetName,
      dbField: 'attribute_option_display_secondary_description',
      type: 'string',
      relationEntity: 'attributes',
    },
    attributeOptionId: { dataSetName, dbField: 'attribute_option_id', type: 'id', relationEntity: 'attribute-options' },
    customerConsignmentRequired: { dataSetName, dbField: 'customer_consignment_required', isEdit: true },
    customerFinishedGoodRequired: { dataSetName, dbField: 'customer_finished_good_required', isEdit: true },
    promptExtraConsignment: { dataSetName, dbField: 'prompt_extra_consignment', isEdit: true },
    salesPriceComputeMode: { dataSetName, dbField: 'sales_price_compute_mode' },
    reportingTagsCount: { dataSetName, parse: (item) => item.reportingTags?.length || 0 },
    reportingTags: {
      dataSetName,
      dbField: 'reportingTags',
      type: 'array',
      parse: (item) =>
        item.reportingTags?.map((reportingTag) => parseReportingTag(entityName, reportingTag)) ?? [],
    },
    salesCustomerName: { dataSetName, dbField: 'sales_customer_display_name' },
    salesCustomerId: { dataSetName, dbField: 'sales_customer_id', type: 'id', relationEntity: 'contacts' },
    isCustomerSpecific: { dataSetName, dbField: 'is_customer_specific', type: 'boolean' },
    configurableType: { dataSetName, dbField: 'configurable_type', type: 'string' },
    configurableTemplateId: {
      dataSetName,
      dbField: 'configurable_template_id',
      type: 'id',
      relationEntity: 'items',
    },
    isConfigurable: { dataSetName, dbField: 'is_configurable', type: 'boolean' },
    endVSInfo: {
      parse: (item) => ({
        endVSRank: item.manufacturing_order_output_end_vs_rank,
        endVSId: item.manufacturing_order_output_end_vs_id,
        endStepRank: item.manufacturing_order_output_end_step_rank,
        endStepId: item.manufacturing_order_output_end_step_id,
      }),
    },
    associatedRawTemplateTitle: { dataSetName, dbField: 'associated_raw_template_title' },
    configurableTemplateTitle: { dataSetName, dbField: 'configurable_template_title' },
    variants: {
      dataSetName,
      dbField: 'variants',
      parse: (item) => item.variants?.map((variant) => parseItem(variant)) || [],
    },
    rawId: { dataSetName, dbField: 'raw_id', type: 'id', relationEntity: 'raws' },
    unitSellingPrince: { dataSetName, dbField: 'unit_selling_price' },
    longDescriptionSale: { dataSetName, dbField: 'long_description_sale',
      customFieldTranslationKey: (t, options) =>
        t('items:item.fields.longDescriptionSale', { language: options.primaryLanguage }),
    },
    longSecondaryDescriptionSale: { dataSetName, dbField: 'long_secondary_description_sale',
      customFieldTranslationKey: (t, options) =>
        t('items:item.fields.longSecondaryDescriptionSale', { language: options.secondaryLanguage }),
    },
    isToleranceOver: { dataSetName, dbField: 'is_tolerance_over' },
    isToleranceUnder: { dataSetName, dbField: 'is_tolerance_under' },
    toleranceOver: { dataSetName, dbField: 'tolerance_over' },
    toleranceUnder: { dataSetName, dbField: 'tolerance_under' },
    updateItemOnPurchaseOrderIssue: { dataSetName, dbField: 'update_item_on_purchase_order_issue' },
    tareTypeId: { dataSetName, dbField: 'tare_type_id', type: 'id', relationEntity: 'tares' },
    isAlternativeItemsAllowed: { dataSetName, dbField: 'is_alternative_items_allowed' },
    isPrintable: { dataSetName, dbField: 'is_printable' },
  }
}
export function getRecipeFields() {
  return {
    id: { dataSetName: recipeDataSetName, dbField: 'id' },
    title: { dataSetName: recipeDataSetName, dbField: 'title' },
    plantId: { dataSetName: recipeDataSetName, dbField: 'plant_id' },
    isPreferred: { parse: (recipe, { manufacturingOrderId }) => recipe.id === manufacturingOrderId },
    currentStepId: { dataSetName: recipeDataSetName, dbField: 'current_step_id', type: 'id' },
  }
}

export function fetchItemsCount(data, mapData, itemsCountKey = null) {
  return async function fetchItemsCountThunk(dispatch) {
    const count = await _fetchItemsCount(data, mapData)
    dispatch({ type: GET_ITEMS_COUNT, payload: { count, itemsCountKey } })
    return count
  }
}

export async function _fetchItemsCount(data, mapData = {}) {
  let count = 0

  try {
    const parsedData = parseFetchItemsData(data, mapData)
    const result = await (await safeFetch(buildGetUrl('/new_api/inventories/templates/count', parsedData))).json()
    if (result.isSuccess) {
      count = +result.result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function fetchItems(data, mapData) {
  return async function fetchItemsThunk(dispatch) {
    const items = await _fetchItems(data, mapData)
    dispatch({ type: GET_ITEMS, payload: items })
    return items
  }
}

export async function _fetchItems(data, mapData = {}) {
  let items = []

  try {
    const parsedData = parseFetchItemsData(data, mapData)
    const result = await (await safeFetch(buildGetUrl('/new_api/inventories/templates', parsedData))).json()
    if (result.isSuccess) {
      items = result.result.map((item) => parseItem(item, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return items
}

export function fetchItemByIds(ids = [], mapData = {}) {
  return async function fetchItemByIdsWithDispatchThunk(dispatch) {
    const items = await _fetchItemByIds(ids, mapData)
    dispatch({ type: GET_ITEMS, payload: items })
    return items
  }
}

export async function _fetchItemByIds(ids = [], mapData = {}) {
  let items = []

  if (ids.length) {
    try {
      const result = await (await safeFetch(`/new_api/inventories/templates/${ids}`)).json()
      if (result.isSuccess) {
        items = result.result
          .map((item) => parseItem(item, mapData))
      }
    } catch (err) {
      console.error(err)
    }
  }

  return items
}

export async function _fetchItemVendors(itemIds, mapData, fields, data = {}) {
  let itemVendors = []

  try {
    const result = await (await safeFetch(buildGetUrl(
      `/new_api/inventories/templates/${itemIds}/suppliers`, data))).json()
    if (result.isSuccess) {
      itemVendors = result.result.sort((a, b) => a.ranking - b.ranking).map((itemVendor) => {
        const _parsed = { ...itemVendor }
        _parsed.vendor = _extractVendorFromItemVendor(_parsed)
        return parseItemVendor(_parsed, mapData, fields)
      })
    }
  } catch (err) {
    console.error(err)
  }

  return itemVendors
}

export async function fetchItemsRecipe(data, manufacturingOrderId) {
  let recipes = []

  try {
    const result = await (await safeFetch(buildGetUrl(`/new_api/items`, data))).json()
    if (result.isSuccess) {
      recipes = result.result.map((recipe) => parseRecipe(recipe, manufacturingOrderId))
    }
  } catch (err) {
    console.error(err)
  }

  return recipes
}

function _extractVendorFromItemVendor(itemVendor) {
  const relevantDataSets = ['currency']
  const vendor = {}

  Object.keys(itemVendor).filter((property) =>
    property.startsWith('contact_') ||
    relevantDataSets.some((dataSet) => property.startsWith(dataSet)),
  ).forEach((property) => {
    const relevantDataSet = relevantDataSets.find((dataSet) => property.startsWith(dataSet))
    if (relevantDataSet) {
      vendor[property] = itemVendor[property]
    } else {
      vendor[property.replace('contact_', '')] = itemVendor[property]
      delete itemVendor[property]
    }
  })

  return parseContact(vendor)
}

export function createItem(data, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ templates: [data] }),
  }
  return async function createItemThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/inventories/templates`, requestOptions)).json()
      const payload = result.isSuccess ? parseItem(result.result[0], mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_ITEM, payload, error })
      return { item: payload, isSuccess: result.isSuccess }
    } catch (error) {
      dispatch({ type: CREATE_ITEM, error })
      return { isSuccess: false }
    }
  }
}

export function updateItem(data, mapData) {
  return async function updateItemThunk(dispatch) {
    await _updateItem(dispatch, data, mapData)
  }
}

async function _updateItem(dispatch, data, mapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ templates: [data] }),
  }

  try {
    const result = await(await safeFetch(
      `/new_api/inventories/templates/${data.id}`,
      requestOptions,
    )).json()
    const [updated] = result.isSuccess ? result.result : []
    const payload = updated ? { ...parseItem(updated, mapData) } : null
    const error = !result.isSuccess ? result.result : null
    dispatch({ type: UPDATE_ITEM, payload, error })

    return { isSuccess: result.isSuccess, result: payload }
  } catch (error) {
    dispatch({ type: UPDATE_ITEM, error })
    return { isSuccess: false, result: error }
  }
}

export async function _updateItems(ids, data, mapData) {
  // core only supports one template update at a time on multiple ids
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ templates: data }),
  }

  const response = await(await safeFetch(
    `/new_api/inventories/templates/${ids.join(',')}`,
    requestOptions,
  )).json()

  return parsedResultOnSucessOrEmtpy(response, parseItem, mapData?.defaultUnits)
}

export function duplicateItem(itemId, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ toCopyId: itemId }),
  }

  return async function duplicateItemThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/inventories/templates/copy/`,
        requestOptions,
      )).json()
      const payload = result.isSuccess ? parseItem(result.result, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: COPY_ITEM, payload: result.isSuccess, error })

      return { item: payload, isSuccess: result.isSuccess }
    } catch (error) {
      dispatch({ type: COPY_ITEM, error })
      return { isSuccess: false }
    }
  }
}

export function deleteItems(itemIds) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }

  return async function deleteItemsThunk(dispatch) {
    try {
      const result = await (await safeFetch(
        `/new_api/inventories/templates/${itemIds}`,
        requestOptions,
      )).json()
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: DELETE_ITEM, payload: result.isSuccess, error })

      return result
    } catch (error) {
      dispatch({ type: DELETE_ITEM, error })
    }
  }
}

export function clearItems(dispatch) {
  dispatch({ type: CLEAR_ITEMS })
}

export function createVariant(data, mapData = {}) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }
  return async function createItemThunk(dispatch) {
    try {
      const result = await (await safeFetch(`/new_api/inventories/templates/variants`, requestOptions)).json()
      const payload = result.isSuccess ? parseItem(result.result, mapData) : null
      const error = !result.isSuccess ? result.result : null
      dispatch({ type: CREATE_ITEM, payload, error })
      return { item: payload, isSuccess: result.isSuccess }
    } catch (error) {
      dispatch({ type: CREATE_ITEM, error })
      return { isSuccess: false }
    }
  }
}

function parseFetchItemsData(data = {}, mapData = {}) {
  let conditionObj = data.conditionObj ? JSON.parse(data.conditionObj) : []
  if (mapData.isSelling) {
    conditionObj = conditionObj.filter((condition) => condition.column != 'is_selling')
    conditionObj.push({ dataSetName, column: 'is_selling', value: true })
  }
  if (mapData.isPurchased) {
    conditionObj = conditionObj.filter((condition) => condition.column != 'is_purchased')
    conditionObj.push({ dataSetName, column: 'is_purchased', value: true })
  }
  if (mapData.status) {
    conditionObj = conditionObj.filter((condition) => condition.column != 'status')
    conditionObj.push({ dataSetName, column: 'status', value: mapData.status })
  }
  if (mapData.isConsignment) {
    data.isConsignment = true
    data.consignmentCustomerId = mapData.customerDetails?.id
  }
  if (!!mapData.salesCustomerId) {
    data.salesCustomerId = mapData.customerDetails?.id
  }
  if (mapData.type) {
    data.type = mapData.type
  }
  if (mapData.dimension) {
    data.dimension = mapData.dimension
  }
  if (mapData.preferredIds) {
    data.preferredIds = mapData.preferredIds
  }
  if (typeof mapData.isConfigurable === 'boolean') {
    data.isConfigurable = mapData.isConfigurable
  }
  if (mapData.attributeId) {
    data.attributeId = mapData.attributeId
  }
  if (mapData.attributeOptionId) {
    data.attributeOptionId = mapData.attributeOptionId
  }
  if (mapData.notHavingVariantsWithAttributeOptionId) {
    data.notHavingVariantsWithAttributeOptionId = mapData.notHavingVariantsWithAttributeOptionId
  }
  if (mapData.associatedRawTemplateId) {
    data.associatedRawTemplateId = mapData.associatedRawTemplateId
  }
  if (mapData.filterOutIds?.length > 0) {
    data.filterOutIds = mapData.filterOutIds
  }
  if (mapData.configurableTemplateId) {
    data.configurableTemplateId = mapData.configurableTemplateId
  }
  if (typeof mapData.includeConfigurableTemplate === 'boolean') {
    data.includeConfigurableTemplate = mapData.includeConfigurableTemplate
  }

  return { ...data, conditionObj: JSON.stringify(conditionObj) }
}

export function updateItems(items) {
  return async function updateItemsThunk(dispatch) {
    dispatch({ type: GET_ITEMS, payload: items })
  }
}

/**
 * @param {Partial<ReturnType<getVendorFields|getVendorFieldsForPlanning>>} fields
 */
export function parseItemVendor(itemVendor, mapData = {}, fields = initialState.itemVendorFields) {
  const options = {
    fields,
    dataSetName: itemVendorDataSetName,
    defaultUnits: mapData.defaultUnits,
    relatedItem: mapData.planningItemDict?.[itemVendor.template_id] || mapData.relatedItem || {},
    baseCurrency: mapData.baseCurrency || {},
    preferredCompany: mapData.preferredCompany || {},
    prefixItemField: mapData.prefixItemField || ((field) => prefixFieldFromPrefix(field)),
  }
  return parse(itemVendor, options)
}

export function parseItem(item, mapData = {}, overriddenFields = undefined) {
  const vendors = item.suppliers || []
  const options = {
    ...mapData,
    fields: overriddenFields ?? initialState.fields,
    dataSetName,
    defaultUnits: mapData.defaultUnits,
    vendorDetails: mapData.vendorDetails || {},
    customerDetails: mapData.customerDetails || {},
    preferredCompany: mapData.preferredCompany || {},
    matchingVendor: vendors.find((templateVendor) => templateVendor.supplier_id === mapData.vendorDetails?.id) || {},
  }

  return parse(item, options)
}

export function parseRecipe(recipe, manufacturingOrderId) {
  const options = {
    fields: initialState.recipeFields,
    dataSetName: recipeDataSetName,
    defaultData: parse({}, { fields: initialState.recipeFields }),
    manufacturingOrderId,
  }
  return parse(recipe, options)
}

function parseUnitCost(item = {}, options) {
  const vendors = item.suppliers || []
  if (options.matchingVendor.id) {
    return options.matchingVendor.unit_cost || 0
  } else if (vendors.length > 0 || (options.vendorDetails.id && !options.vendorDetails.currencyIsBase)) {
    return 0
  } else {
    return +item.planned_unit_cost || 0
  }
}

function parseVendorListPrice(item = {}, options) {
  const vendors = item.suppliers || []
  if (options.matchingVendor.id) {
    return +options.matchingVendor.list_price || 0
  } else if (vendors.length > 0 || (options.vendorDetails.id && !options.vendorDetails.currencyIsBase)) {
    return 0
  } else {
    return +item.planned_unit_cost || 0
  }
}

function parseVendorDiscount(item, options) {
  return +options.matchingVendor.discount || 0
}

function getSellingPrice(item = {}, options) {
  const exchangeRate = +options.customerDetails?.currencyExchangeRate || 1
  return (item.is_in_price_list ? +item.unit_selling_price : +item.unit_selling_price/exchangeRate) || 0
}

function parseSellingPrice(item = {}, options) {
  const dimension = item.dimension_to_display
  const sellingPrice = getSellingPrice(item, options)
  return convertFromDollarPerBase(dimension, sellingPrice, getSellingUnit(item, options)) || 0
}

function getMeasureUnit(item = {}, options = {}) {
  const conversionFactor = +options.matchingVendor.conversion_factor || 0
  return conversionFactor > 0 ? (options.matchingVendor.custom_unit || '') : getItemMeasureUnit(item, options)
}

function getMainVendorMeasureUnit(item = {}, options = {}) {
  const conversionFactor = +item.main_supplier_conversion_factor || 0
  return conversionFactor > 0 ? (item.main_supplier_custom_unit || '') : getItemMeasureUnit(item, options)
}

function getItemMeasureUnit(item = {}, options = {}) {
  const dimension = item.dimension_to_display
  const defaultUnits = options.defaultUnits || {}

  return item.measure_unit || defaultUnits[dimension]
}

function getWeightUnit(item = {}, options = {}) {
  const defaultUnits = options.defaultUnits || {}
  return item.weight_unit || defaultUnits['weight']
}

function getSurfaceUnit(item = {}, options = {}) {
  const defaultUnits = options.defaultUnits || {}
  return item.surface_unit || defaultUnits['surface']
}

function getVolumeUnit(item = {}, options = {}) {
  const defaultUnits = options.defaultUnits || {}
  return item.volume_unit || defaultUnits['volume']
}

function getSellingUnit(item = {}, options) {
  return item.selling_unit || getItemMeasureUnit(item, options)
}

function parseMoq(item = {}, options) {
  return +item.min_selling_measure || 0
}

function parsePurchaseMoq(item = {}, options) {
  return (options.matchingVendor.id ? +options.matchingVendor.minimum_order_qty : +item.initial_measure) || 0
}

function parsePartNumber(item = {}, options) {
  return options.matchingVendor.part_number || item.part_number
}

function getDescriptionToVendorLanguage(item = {}, options) {
  return _getDescriptionToContactLanguage(
    item,
    options.vendorDetails,
    options.preferredCompany,
    options.prefixItemField,
    options.matchingVendor,
    options.isCamel,
  )
}

function getLongDescriptionToVendorLanguage(item = {}, options) {
  return _getLongDescriptionToContactLanguage(
    item,
    options.vendorDetails,
    options.preferredCompany,
    options.prefixItemField,
    options.matchingVendor,
    'purchase',
    options.isCamel,
  )
}

function getDescriptionToCustomerLanguage(item = {}, options) {
  return _getDescriptionToContactLanguage(
    item,
    options.customerDetails,
    options.preferredCompany,
  )
}

function getLongDescriptionToCustomerLanguage(item = {}, options) {
  return _getLongDescriptionToContactLanguage(
    item,
    options.customerDetails,
    options.preferredCompany,
  )
}

function _getDescriptionToContactLanguage(
  item = {},
  contactDetails,
  preferredCompany,
  prefixItemField = (field) => prefixFieldFromPrefix(field),
  matchingInfo,
  isCamel,
) {
  let descriptionField = 'description'
  let secondaryDescriptionField = 'secondary_description'
  if (isCamel) {
    descriptionField = toCamel(descriptionField)
    secondaryDescriptionField = toCamel(secondaryDescriptionField)
  }
  descriptionField = prefixItemField(descriptionField)
  secondaryDescriptionField = prefixItemField(secondaryDescriptionField)
  const isSecondaryLanguage = contactDetails.id && contactDetails.language ?
    contactDetails.language.split('-')[0] === preferredCompany.secondaryLanguage :
    false
  return (
    matchingInfo?.description || (
      isSecondaryLanguage ? item[secondaryDescriptionField] || item[descriptionField] : item[descriptionField]
    )
  )
}

function _getLongDescriptionToContactLanguage(
  item = {},
  contactDetails,
  preferredCompany,
  prefixItemField = (field) => prefixFieldFromPrefix(field),
  matchingInfo,
  type = 'sale',
  isCamel,
) {
  let descriptionField = `long_description_${type}`
  let secondaryDescriptionField = `long_secondary_description_${type}`
  if (isCamel) {
    descriptionField = toCamel(descriptionField)
    secondaryDescriptionField = toCamel(secondaryDescriptionField)
  }
  descriptionField = prefixItemField(descriptionField)
  secondaryDescriptionField = prefixItemField(secondaryDescriptionField)
  const isSecondaryLanguage = contactDetails.id && contactDetails.language ?
    contactDetails.language.split('-')[0] === preferredCompany.secondaryLanguage :
    false
  return (
    matchingInfo?.long_description || (
      isSecondaryLanguage ? item[secondaryDescriptionField] || item[descriptionField] : item[descriptionField]
    )
  )
}

function parseTrimmedInstructions(item = {}, options) {
  return options.matchingVendor.long_description || item.trimmed_instructions
}

function getMatchingVendorConversionFactor(item = {}, options = {}, isInitial = false) {
  if (isInitial) {
    return +options.matchingVendor.conversion_factor || 0
  }

  return +options.matchingVendor.conversion_factor > 0 ?
    +options.matchingVendor.conversion_factor :
    getSingleToBase(item, options)
}

function getMainConversionFactor(item = {}, options) {
  return +item.main_supplier_conversion_factor > 0 ?
    +item.main_supplier_conversion_factor :
    getSingleToBase(item, options)
}

function getSingleToBase(item = {}, options = {}) {
  const dimension = item.dimension_to_display
  const unit = getItemMeasureUnit(item, options)

  return convertToBase(dimension, 1, unit)
}

export function getItemTitle(item) {
  return item.title
}
