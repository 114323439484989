import { useEffect } from 'react'

import { v4 as uuid } from 'uuid'
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper'

const browserPrint = new ZebraBrowserPrintWrapper()

import { defaultPromise } from 'utils/defaultValueHelper'
import { formatAddressCity } from 'utils/print/fields'
import { useData } from 'utils/useData'

import { fetchConsignmentItemsByIds } from 'reducers/consignment-items/consignmentItemsSlice'
import { fetchSalesOrderItemByIds } from 'reducers/sales-orders/salesOrderItemsSlice'
import { _fetchSalesOrders } from 'reducers/sales-orders/salesOrdersSlice'

import { getDatabaseFieldDictionary, latinChars } from './fields'

import {
  PrintModule,
  PrintArguments,
  buildLabelFields,
  buildRawLabel,
  assignValueToRawLabel,
  getInventoryManufacturingOrder,
  getConsignmentProductionDict,
  getPlannedLedgersPromise,
  getPlannedTargetInventoryFromItems,
} from '..'

export function useZebraFetchPrinters(isZebraEnabled = false) {
  const [data, setData] = useData({
    statusFetchPrinters: 'initial',
    printers: [],
    error: null,
  })

  useEffect(() => {
    if (isZebraEnabled) {
      setData({ statusFetchPrinters: 'loading' })

      getPrinters().then((printers) => setData({
        statusFetchPrinters: 'success',
        printers,
        error: null,
      }))
    }
  }, [setData, isZebraEnabled])

  return data
}

export function getPrinters() {
  return browserPrint.getAvailablePrinters().then(
    (printers) => (printers || []).map((printer) => ({ ...printer, id: uuid(), type: 'zebra' })),
  )
}

export async function print({
  t,
  type,
  printer,
  items,
  companyInfo,
  dbLabel,
  printQuantity,
  settings,
}: PrintArguments) {
  const salesItems = items.filter((item) => item.projectType === 'sales')
  const consignmentItemIds = items.filter((item) => !!item.consignmentItemId)
    .map(({ consignmentItemId }) => consignmentItemId)
  const salesOrderItemIds = items
    .filter((item) => !!item.salesOrderItemId)
    .map(({ salesOrderItemId }) => salesOrderItemId)
  const [_items, moInfos, salesOrders, salesOrderItems, consignmentItems, plannedLedgers] = await Promise.all([
    defaultPromise(
      () => getPlannedTargetInventoryFromItems(items),
      type === 'inventory' && settings.inventoryPrintingTarget === 'print_targeted_inventory',
      items,
    ),
    defaultPromise(
      () => Promise.all(items.map((item) => getInventoryManufacturingOrder(item))),
      type === 'inventory',
      [],
    ),
    defaultPromise(
      () => _fetchSalesOrders({ projectId: salesItems.map((salesItem) => salesItem.projectId) }),
      salesItems.length > 0,
      [],
    ),
    defaultPromise(
      () => fetchSalesOrderItemByIds(salesOrderItemIds),
      type === 'item' && salesOrderItemIds.length > 0,
      [],
    ),
    defaultPromise(
      () => fetchConsignmentItemsByIds(consignmentItemIds),
      consignmentItemIds.length > 0,
      [],
    ),
    getPlannedLedgersPromise({ type, dbLabel, items }, printer.type),
  ])
  const consignmentItemProductionDict = await getConsignmentProductionDict(consignmentItems)
  for (let index = 0; index < items.length; index++) {
    const item = _parseItem(_items[index], companyInfo)
    const module: PrintModule = {
      item,
      type,
      companyInfo,
      dbLabel,
      printQuantity,
      printerType: printer.type,
      buildLabelFields: (module: PrintModule) => buildLabelFields(module, '<%', '%>'),
      getDatabaseFieldDictionary,
      settings,
      latinChars,
      consignmentItem: consignmentItems.find((consignmentItem) => consignmentItem.id === item.consignmentItemId),
      salesOrder: salesOrders.find((salesOrder) => salesOrder.projectId === item.projectId),
      salesOrderItem: salesOrderItems.find((salesOrderItem) => salesOrderItem.id === item.salesOrderItemId),
      plannedLedger: plannedLedgers.find((plannedLedger) => plannedLedger.inventoryId === item.id),
      moInfo: moInfos.find((moInfo) => moInfo.inventoryId === item.id),
      consignmentItemProductionDict,
    }

    await buildRawLabel(t, module, (labelField, values) => assignValueToRawLabel(module, labelField.name, values[0]))

    for (let index2 = 1; index2 <= module.printQuantity; index2++) {
      // Uncomment for testing
      // navigator.clipboard.writeText(module.rawLabel)

      // comment while testing
      browserPrint.setPrinter(printer as typeof browserPrint.device)
      await browserPrint.print(module.rawLabel)
    }
  }
}

function _parseItem(item, companyInfo) {
  const itemCopy = { ...item }
  itemCopy.zebraCompany = companyInfo.company_name
  itemCopy.zebraStreet = companyInfo.street
  itemCopy.zebraCity = formatAddressCity(companyInfo.city, companyInfo.province, companyInfo.postalcode)
  itemCopy.phone = companyInfo.phone
  return itemCopy
}
