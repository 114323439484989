export const SET_ACTIVE_DATA = 'SMART_FORM_SET_ACTIVE_DATA'
export const UPDATE_DATA = 'SMART_FORM_UPDATE_DATA'
export const UPDATE_LINE_ITEMS_DATA = 'SMART_FORM_UPDATE_LINE_ITEMS_DATA'
export const REFRESH_LINE_ITEM_BY_ID = 'SMART_FORM_REFRESH_LINE_ITEM_BY_ID'
export const INIT_FORM = 'SMART_FORM_ADD_FORM'
export const SET_MODE = 'SMART_FORM_SET_MODE'
export const DISCARD_CHANGES = 'SMART_FORM_DISCARD_CHANGES'
export const ADD_CREATED_DATA = 'SMART_FORM_ADD_CREATED_DATA'
export const REMOVED_ACTIVE_DATA = 'SMART_FORM_REMOVED_ACTIVE_DATA'
export const SET_OPTIONS = 'SMART_FORM_SET_OPTIONS'
export const REMOVE_FORM = 'SMART_FORM_REMOVE_FORM'
export const REFRESH_DATA = 'SMART_FORM_REFRESH_DATA'
export const REFRESH_DATA_COUNT = 'SMART_FORM_REFRESH_DATA_COUNT'
export const SET_ACTIVE_FORM_DATA = 'SMART_FORM_SET_ACTIVE_FORM_DATA'
export const SET_FETCHED_OPTIONS = 'SMART_FORM_SET_FETCHED_OPTIONS'

export const SET_FETCH_RESULT = 'SMART_FORM_SET_FETCH_RESULT'
export const REMOVE_FETCH_RESULT = 'SMART_FORM_REMOVE_FETCH_RESULT'

export const ADD_ON_FETCH_CALLBACK = 'SMART_FORM_ADD_ON_FETCH_CALLBACK'

export const SET_LOADING_STATE = 'SMART_FORM_SET_LOADING_STATE'
export const SET_DETAILS_LOADING_STATE = 'SMART_FORM_SET_DETAILS_LOADING_STATE'
export const ADD_JOB = 'SMART_FORM_ADD_JOB'
export const REMOVE_JOB = 'SMART_FORM_REMOVE_JOB'

export const SET_IDENTIFICATION = 'SMART_FORM_SET_IDENTIFICATION'
export const SET_SMART_PIN_REF = 'SMART_FORM_SET_SMART_PIN_REF'
export const SET_SMART_FORM_RELATED_REF = 'SET_SMART_FORM_RELATED_REF'
export const SET_LOGIN_RESOURCE = 'SMART_FORM_SET_LOGIN_RESOURCE'
