import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

import { parseSynonym, Synonym, SynonymApi } from 'reducers/synonyms/synonymsSlice'

const dataSetName = 'stateView'

const initialState = {
  dataSetName,
  fields: getFields(),
  statesCount: 0,
  states: [],
}

const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    setStates: (state, action) => {
      state.states = action.payload.data
    },
    setStatesCount: (state, action) => {
      state.statesCount = action.payload.count
    },
  },
})

export const { setStates, setStatesCount } = statesSlice.actions

export type StateApi = Modify<{
  name: string
  secondary_name: string
  code: string
  country_id: string
  country_name: string
  country_secondary_name: string
  country_code: string
  possible_names: string[]
  synonyms: SynonymApi[]
}, BaseEntityApi>

type Exceptions = {
  synonyms: 'lineItems'
}

export type State = ApiToSlice<Modify<StateApi, {synonyms: Synonym[]}>, Exceptions>

export type MapData = {
  primaryLanguage?: string
  secondaryLanguage?: string
}

type StateGetFields = GetFields<StateApi, State>

export function getFields(editOnly = false): StateGetFields {
  const editFields: StateGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    // ! TODO (bzoretic): rework pour que ce soit synonyms et non lineItems (#DEV-I2282)
    'lineItems': {
      dataSetName,
      dbField: 'synonyms',
      type: 'array',
      isEdit: true,
      parse: (stateApi) => stateApi.synonyms?.map((synonym) => parseSynonym(synonym)),
    },
  }

  if (editOnly) return editFields

  const fields: StateGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'name': {
      dataSetName,
      dbField: 'name',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('states:state.fields.name.label', { language: options.primaryLanguage })
      },
    },
    'secondaryName': {
      dataSetName,
      dbField: 'secondary_name',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('states:state.fields.name.label', { language: options.secondaryLanguage })
      },
    },
    'code': { dataSetName, dbField: 'code', type: 'string' },
    'countryCode': { dataSetName, dbField: 'country_code', type: 'string' },
    'countryName': {
      dataSetName,
      dbField: 'country_name',
      type: 'string',
      headerOptions: { language: 'primaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('states:state.fields.country.label', { language: options.primaryLanguage })
      },
    },
    'countrySecondaryName': {
      dataSetName,
      dbField: 'country_secondary_name',
      type: 'string',
      headerOptions: { language: 'secondaryLanguage' },
      customFieldTranslationKey: (t, options) => {
        return t('states:state.fields.country.label', { language: options.secondaryLanguage })
      },
    },
    'countryId': { dataSetName, dbField: 'country_id', type: 'id', relationEntity: 'countries' },
    'possibleNames': { dataSetName, dbField: 'possible_names', type: 'array' },
  }

  return { ...fields, ...editFields }
}

export function getStateTitle(state: State): string {
  return state.name
}

export async function fetchStatesByIds(ids: string[], data?: Record<string, any>, mapData?: MapData) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<StateApi>(
    buildGetUrl(`/new_api/states/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((state) => parseState(state, mapData)) :
    []
}

export function fetchStates(fetchData?: Record<string, any>, mapData?: MapData) {
  return async function fetchStatesThunk(dispatch: AppDispatch) {
    const data = await _fetchStates(fetchData, mapData)
    dispatch(setStates({ data }))
    return data
  }
}

export async function _fetchStates(fetchData: Record<string, any>, mapData?: MapData) {
  let states = []

  try {
    const { isSuccess, result } = await safeFetchJson<StateApi>(
      buildGetUrl('/new_api/states', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      states = result.map((state) => parseState(state, mapData))
    }
  } catch (err) {
    console.error(err)
  }

  return states
}

export function fetchStatesCount(fetchData?: Record<string, any>) {
  return async function fetchStatesCountThunk(dispatch: AppDispatch) {
    const count = await _fetchStatesCount(fetchData)
    dispatch(setStatesCount({ count }))
    return count
  }
}

export async function _fetchStatesCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/states/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export async function _updateState(id: string, updateData: Partial<StateApi>, mapData?: MapData) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ state: updateData }),
  }

  try {
    const { isSuccess, result } = await safeFetchJson<StateApi, false>(
      `/new_api/states/${id}`, requestOptions,
    )
    const state = isSuccess && !isJob<StateApi, false>(result) ?
      parseState(result, mapData) :
      null
    const error = !isSuccess ? result : null
    return { isSuccess, state, error }
  } catch (error) {
    console.error(error)
    return { isSuccess: false, error }
  }
}

export function parseState(state: StateApi, mapData?: MapData): State {
  const options = {
    ...mapData,
    defaultData: getDefaultState(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(state, options)
}

function getDefaultState(): State {
  return parse({}, { fields: initialState.fields })
}

export default statesSlice.reducer
