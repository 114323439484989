import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch } from 'store'
import { ApiToSlice, BaseEntityApi, GetFields, Modify } from 'types/slices'

import { buildGetUrl, parse } from 'utils/api'
import { isJob, safeFetchJson } from 'utils/safeFetch'

const dataSetName = 'synonymView'

const initialState = {
  dataSetName,
  fields: getFields(),
  synonymsCount: 0,
  synonyms: [],
}

const synonymsSlice = createSlice({
  name: 'synonyms',
  initialState,
  reducers: {
    setSynonyms: (state, action) => {
      state.synonyms = action.payload.data
    },
    setSynonymsCount: (state, action) => {
      state.synonymsCount = action.payload.count
    },
  },
})

export const { setSynonyms, setSynonymsCount } = synonymsSlice.actions

export type SynonymApi = Modify<{
  name: string
  entity_name: 'countries' | 'states'
  entity_id: string
}, BaseEntityApi>

export type Synonym = ApiToSlice<SynonymApi>

type SynonymGetFields = GetFields<SynonymApi, Synonym>

export function getFields(editOnly = false): SynonymGetFields {
  const editFields: SynonymGetFields = {
    'id': { dataSetName, dbField: 'id', type: 'string' },
    'name': { dataSetName, dbField: 'name', type: 'string', isEdit: true },
  }

  if (editOnly) return editFields

  const fields: SynonymGetFields = {
    'exist': { dataSetName, dbField: 'exist', type: 'boolean' },
    'createdDate': { dataSetName, dbField: 'created_date', type: 'timestamp' },
    'createdBy': { dataSetName, dbField: 'created_by', type: 'string' },
    'createdById': { dataSetName, dbField: 'created_by_id', type: 'string' },
    'modifiedDate': { dataSetName, dbField: 'modified_date', type: 'timestamp' },
    'modifiedBy': { dataSetName, dbField: 'modified_by', type: 'string' },
    'modifiedById': { dataSetName, dbField: 'modified_by_id', type: 'string' },
    'entityName': { dataSetName, dbField: 'entity_name', type: 'string' },
    'entityId': { dataSetName, dbField: 'entity_id', type: 'string' },
  }

  return { ...fields, ...editFields }
}

export function getSynonymTitle(synonym: Synonym): string {
  return synonym.name
}

export async function fetchSynonymsByIds(ids: string[], data?: Record<string, any>) {
  if (!ids?.length) return []

  const { isSuccess, result } = await safeFetchJson<SynonymApi>(
    buildGetUrl(`/new_api/synonyms/${ids}`, data),
  )

  return isSuccess && !isJob(result) ?
    result.map((synonym) => parseSynonym(synonym)) :
    []
}

export function fetchSynonyms(fetchData?: Record<string, any>) {
  return async function fetchSynonymsThunk(dispatch: AppDispatch) {
    const data = await _fetchSynonyms(fetchData)
    dispatch(setSynonyms({ data }))
    return data
  }
}

export async function _fetchSynonyms(fetchData: Record<string, any>) {
  let synonyms = []

  try {
    const { isSuccess, result } = await safeFetchJson<SynonymApi>(
      buildGetUrl('/new_api/synonyms', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      synonyms = result.map((synonym) => parseSynonym(synonym))
    }
  } catch (err) {
    console.error(err)
  }

  return synonyms
}

export function fetchSynonymsCount(fetchData?: Record<string, any>) {
  return async function fetchSynonymsCountThunk(dispatch: AppDispatch) {
    const count = await _fetchSynonymsCount(fetchData)
    dispatch(setSynonymsCount({ count }))
    return count
  }
}

export async function _fetchSynonymsCount(fetchData?: Record<string, any>) {
  let count = 0

  try {
    const { isSuccess, result } = await safeFetchJson<{count: string}>(
      buildGetUrl('/new_api/synonyms/count', fetchData),
    )
    if (isSuccess && !isJob(result)) {
      count = +result[0].count || 0
    }
  } catch (err) {
    console.error(err)
  }

  return count
}

export function parseSynonym(synonym: SynonymApi): Synonym {
  const options = {
    defaultData: getDefaultSynonym(),
    fields: initialState.fields,
    dataSetName: dataSetName,
  }

  return parse(synonym, options)
}

function getDefaultSynonym(): Synonym {
  return parse({}, { fields: initialState.fields })
}

export default synonymsSlice.reducer
